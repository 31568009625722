import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  Card,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { useNavigate, useParams } from 'react-router-dom';

import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { actions as blogActions } from '../../../../store/ducks/blog.duck';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { IProduct } from '../../../../interfaces/product';

import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import homeStyles from '../../homeStyles';
import { a11yProps, TabPanel } from '../../../../components/other/Tab/TabPanel';
import { useFormatMessage, useShowErrors, useTabs } from '../../../../hooks';
import { API_DOMAIN } from '../../../../constants';
import FilesDropzone from '../../../../components/formComponents/FilesDropzone';
import { createChildrenList } from '../../../../utils/createParentList';
import { Modal } from '../../../../components/other/Modals';
import PublicationTable from '../../../../components/tableComponents/Table/PublicationTable';
import { actions as productActions } from '../../../../store/ducks/product.duck';

import { useUploadPublicationImage } from './hooks/uploadPublicationImage';
import { useCustomFormik } from './hooks/useCustomFormik';
import { useStyles } from './hooks/useStyles';
import CkEditorCustom from '../../../../components/formComponents/CkEditorCustom';

interface IField<T> {
  title: string;
  field: T;
}

const EditPublicationPage: React.FC<TPropsFromRedux> = ({
  me,
  loading,
  error,
  success,
  editPublication,
  clearEditPublication,

  publication,
  publicationLoading,
  publicationError,
  fetchPublication,
  clearFetchPublication,
  deletePublication,

  uploadLogoLoading,
  uploadLogoError,

  fetchCategories,
  categories,
  deleteLoading,
  products,
  page,
  perPage,
  total,
  fetch,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const [tabValue, changeTab] = useTabs();
  const [editorState, setEditorState] = useState<any>(null);
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const { values, handleBlur, handleChange, errors, touched, handleSubmit, setFieldValue } =
    useCustomFormik({
      id: id || '',
      data: publication,
      submit: editPublication,
      editorState,
      me,
    });
  const [uploadImageErr, uploadImage] = useUploadPublicationImage(publication?.id);

  setLayoutSubheader({ title: fm('edit_publication') });
  setLayoutFooter({ show: true });

  const handleConnectProduct = useCallback((productId: IProduct) => {
    setOpenModalProducts(false);

    setFieldValue('product_id', productId.id);
    setFieldValue('product_name', productId.name);
  }, []);

  const handleConnectsProduct = useCallback(() => {
    setOpenModalProducts(false);
  }, [products]);

  // const handleConnectUser = useCallback(
  //   (userId, companyProp?: ICompany) => {
  //     const managers = companyProp?.managers ? companyProp?.managers.map(i => i.id) : [];
  //     edit({
  //       id: companyProp ? companyProp.id : +companyId,
  //       favorite: isFav,
  //       data: {
  //         managers_ids: [...managers, userId],
  //       },
  //     });
  //     setOpenUsersDialog(false);
  //   },
  //   [company?.managers, companyId]
  // );
  const handleDeleteDialog = useCallback((id: number) => {
    deletePublication({ id });
    navigate(-1);
  }, []);

  useEffect(() => {
    if (id) {
      fetchPublication({ id: +id });
    }
    return () => {
      clearFetchPublication();
      clearEditPublication();
    };
  }, [id]);

  useEffect(() => {
    if (publicationError) {
      navigate('/blog/all');
    }
  }, [publicationError]);

  useEffect(() => {
    if (success) navigate(-1);
  }, [success, navigate]);

  useEffect(() => {
    fetch({ page, perPage, categoryId: values.category_id || 0 });
  }, [values.category_id]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useShowErrors([error, publicationError, uploadLogoError, uploadImageErr]);

  if (!publication || publicationLoading || deleteLoading) return <Preloader />;

  const FIELDS: IField<keyof typeof values>[] = [
    { title: fm('title'), field: 'title' },
    { title: fm('description'), field: 'description' },
  ];

  const TYPE = [
    { label: 'Админский', value: 'admin' },
    { label: 'Партнерский', value: 'partner' },
    { label: 'Пользовательский', value: 'user' },
  ];

  return (
    <Card className={homeClasses.classes.container}>
      <div className={homeClasses.classes.editContent}>
        <div className={homeClasses.classes.tabContent}>
          <Tabs
            value={tabValue}
            onChange={changeTab}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            aria-label='tabs'
          >
            <Tab label={fm('PUBLICATIONS.TABS.COMMON')} {...a11yProps(0)} />
            <Tab label={fm('PUBLICATIONS.TABS.PHOTOS')} {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <form
            onSubmit={handleSubmit}
            className={homeClasses.classes.form}
            autoComplete='off'
          >
            {FIELDS.map(item => {
              return (
                <TextField
                  key={item.field}
                  type='text'
                  label={item.title}
                  margin='normal'
                  name={item.field}
                  value={values[item.field]}
                  multiline={item.field === 'description'}
                  rows={item.field === 'description' ? 3 : 0}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched[item.field] && errors[item.field]}
                  error={Boolean(touched[item.field] && errors[item.field])}
                />
              );
            })}

            <TextField
              select
              label={fm('PRODUCT.EDIT.CATEGORY')}
              margin='normal'
              name='category_id'
              value={values.category_id || ''}
              variant='outlined'
              onBlur={handleBlur('category_id')}
              onChange={handleChange}
            >
              {categories &&
                createChildrenList(categories, '\u00B7').map(cat => (
                  <MenuItem key={cat.id?.toString()} value={cat.id?.toString()}>
                    {cat.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              label={fm('PRODUCT.SNACKBAR.TITLE')}
              margin='normal'
              name='product_name'
              variant='outlined'
              value={values.product_name || ''}
              disabled={!values.category_id}
              onClick={() => setOpenModalProducts(true)}
              ref={inputRef}
            />
            {me?.is_admin && (
              <TextField
                select
                label={fm('PRODUCT.EDIT.POST.TYPE')}
                margin='normal'
                name='type'
                value={values.type}
                variant='outlined'
                onBlur={handleBlur('type')}
                onChange={handleChange}
              >
                {TYPE.map(typ => (
                  <MenuItem key={typ.label?.toString()} value={typ.value}>
                    {typ.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {me?.is_admin && (
              <FormControlLabel
                control={
                  <Switch
                    className={homeClasses.classes.switch}
                    size='small'
                    name='favorite'
                    checked={values.favorite}
                    onChange={handleChange}
                    disabled={!me?.is_admin}
                  />
                }
                label={fm('BLOG.EDIT.FAVORITE')}
              />
            )}

            <InputLabel shrink style={{ marginTop: 7 }}>
              {fm('text')}
            </InputLabel>

            <CkEditorCustom
              html={publication?.text || ''}
              setEditorStateCallback={setEditorState}
            />

            <div className={classes.actions}>
              <Button
                onClick={() => navigate(-1)}
                className={classes.cancelBtn}
                variant='outlined'
                color='primary'
              >
                {fm('CATEGORY.BUTTON.CANCEL')}
              </Button>

              <ButtonWithLoader
                disabled={loading || uploadLogoLoading}
                loading={loading || uploadLogoLoading}
                style={{ marginRight: 18, marginLeft: 18 }}
              >
                {fm('COMMON.BUTTON.SAVE')}
              </ButtonWithLoader>

              <Button
                onClick={() => handleDeleteDialog(publication.id)}
                variant='outlined'
                color='secondary'
              >
                {fm('COMMON.BUTTON.DELETE')}
              </Button>
            </div>

            <Modal
              DialogProps={{ maxWidth: 'md' } as DialogProps}
              open={openModalProducts}
              onClose={() => setOpenModalProducts(false)}
              title='Привязка товара к блогу'
              content={
                <PublicationTable
                  onConnect={productId => handleConnectProduct(productId)}
                  products={products || []}
                  onConnectOff={() => handleConnectsProduct()}
                  values={values}
                  fetch={({ page, perPage }) => fetch({ perPage, page })}
                  page={page}
                  perPage={perPage}
                  total={total}
                />
              }
              actions={[
                {
                  title: 'Закрыть',
                  onClick: () => setOpenModalProducts(false),
                },
              ]}
            />
          </form>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div
            style={{
              padding: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Card style={{ marginBottom: 25 }}>
              <img
                src={`${API_DOMAIN}/${publication.avatar}`}
                alt=''
                style={{ maxWidth: 550, maxHeight: 500 }}
              />
            </Card>
            <FilesDropzone
              uploadFiles={uploadImage}
              avaliableNumberOfFilesToUpload={1}
              title={fm('COMMON.PHOTO.LOAD')}
              withCrop
            />
          </div>
        </TabPanel>
      </div>
    </Card>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.products.products,
    page: state.products.page,
    perPage: state.products.per_page,
    total: state.products.total,

    loading: state.blog.editPublicationLoading,
    success: state.blog.editPublicationSuccess,
    error: state.blog.editPublicationError,
    me: state.profile.me,

    publication: state.blog.publication,
    publicationLoading: state.blog.fetchPublicationLoading,
    publicationError: state.blog.fetchPublicationError,

    deleteLoading: state.blog.deletePublicationLoading,

    uploadLogoLoading: state.blog.uploadPhotoLoading,
    uploadLogoError: state.blog.uploadPhotoError,

    categories: state.categories.categories,
  }),
  {
    editPublication: blogActions.editPublicationRequest,
    clearEditPublication: blogActions.clearEditPublication,

    fetchPublication: blogActions.fetchEditPublicationRequest,
    clearFetchPublication: blogActions.clearFetchEditPublication,
    fetchCategories: categoriesActions.fetchFullRequest,

    deletePublication: blogActions.deletePublicationRequest,

    fetch: productActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditPublicationPage);
