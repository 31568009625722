import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Button,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import moment from 'moment';
import TablePaginator from '../../../../components/tableComponents/TablePaginator/TablePaginator';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import AlertDialog from '../../../../components/other/Dialog/AlertDialog';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as tendersActions } from '../../../../store/ducks/tenders.duck';
import { actions as messsageActions } from '../../../../store/ducks/messages.duck';
import homeStyles from '../../../../constants/homeStyles';
import { ListType, tenderTypeList } from '../constatns';
import { ViewMode } from '../TenderPage';
import { UserRoleType } from '../../../../interfaces/user';
import MessagesDialog from '../MessagesDialog';
import { IOrder, ITender } from '../../../../interfaces/order';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { useStyles } from '../hooks/useStyles';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { useInit } from '../../chats/chatsPage/hooks/useInit';
import { setLayoutSubheader } from '../../../../utils/layout';

const getStatusString = (type: string, selectedType?: string, role?: UserRoleType): string => {
  if ((role === 'ROLE_BUYER' || role === 'ROLE_BUYER_STAFF') && type === ListType.MY)
    return tenderTypeList.toString();
  if (role === 'ROLE_BUYER' || role === 'ROLE_BUYER_STAFF')
    return 'report_ready,completed,new,no_report_required';
  switch (type) {
    case ListType.FULL:
      return `${selectedType ? `${selectedType}` : tenderTypeList.toString()}`;
    case ListType.ABANDONED:
      return 'cart';
    default:
      return `[]`;
  }
};

const TendersList: React.FC<
  TPropsFromRedux & { type: string; userId?: number; smallSendMsgBtn?: boolean }
> = ({
  tenders,
  loading,
  fetch,
  page,
  perPage,
  total,
  delOrder,
  delError,
  clearErrors,
  // editStatus,
  clearMessages,
  meLoading,
  fetchMe,
  clearMe,
  me,
  type,
  userId,
  clearOrders,
}) => {
  const homeClasses = homeStyles();
  const classes1 = useStyles();
  const { classes } = classes1;
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const [init] = useInit();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  // const [selectedId, setSelectedId] = useState(-1);
  const [showModalMessages, setShowModalMessages] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<IOrder | null>(null);
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  // const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  // const [selectedType, setSelectedType] = useState<string>('');
  // const defaultType = { value: '', label: fm('TENDER.FILTER.PLACEHOLDER') };

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (deleteId) {
      delOrder({
        page,
        perPage,
        id: deleteId,
        // statuses: getStatusString(type, selectedType),
        statuses: getStatusString(type, ''),
        companyId:
          (isVendor || (isBuyer && type === ListType.MY)) && me?.company
            ? me.company.id
            : undefined,
      });
    }
  }, [deleteId, page, perPage, type, delOrder, isVendor, isBuyer]);

  const editAction = useCallback(
    (item: ITender, mode: string) => {
      navigate(`/tenders/${item.id}/${mode}`);
    },
    [navigate]
  );

  // const selectAction = useCallback(
  //   id => {
  //     setSelectedId(id);
  //   },
  //   [setSelectedId]
  // );

  useLayoutEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (delError) {
      enqueueSnackbar(delError, { variant: 'error' });
      setAlertOpen(false);
      clearErrors();
    }
  }, [delError, clearErrors, setAlertOpen, enqueueSnackbar]);

  useEffect(() => {
    const statuses = getStatusString(type, '', me?.roles[0]);
    // const statuses = getStatusString(type, selectedType, me?.roles[0]);
    if (isVendor || (isBuyer && type === ListType.MY)) {
      me?.company
        ? fetch({ statuses, page, perPage, userId, companyId: me.company.id })
        : clearOrders();
    } else {
      fetch({ statuses, page, perPage, userId });
    }
  }, [type, page, perPage, fetch, userId, isVendor, isBuyer]);

  useEffect(() => {
    if (!me) fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  // const translates: { [type: string]: string } = {
  //   new: intl.formatMessage({ id: 'TENDER.TYPE.NEW' }),
  //   canceled: intl.formatMessage({ id: 'TENDER.TYPE.CANCELED' }),
  //   completed: intl.formatMessage({ id: 'TENDER.TYPE.COMPLETED' }),
  //   in_processing: intl.formatMessage({ id: 'TENDER.TYPE.PROCCESS' }),
  //   needs_clarification: intl.formatMessage({ id: 'TENDER.TYPE.NEED_CERF' }),
  //   report_ready: intl.formatMessage({ id: 'TENDER.TYPE.READY' }),
  // };
  // const orderTypes = getTenderTypeWithTranslates(translates, type === ListType.ABANDONED);

  // const handlerShowMessages = useCallback((item: IOrder) => {
  //   setCurrentResponse(item);
  //   setShowModalMessages(true);
  // }, []);

  const closeMessages = useCallback(() => {
    setShowModalMessages(false);
    setCurrentResponse(null);
    clearMessages();
  }, []);

  if (type === ListType.MY && isBuyer) {
    setLayoutSubheader({
      title: fm('MENU.TENDERS'),
      // id: 'SUBMENU.MYORDERS',
      // btns:
      //   // me?.is_admin || (isVendor && me?.company) || isManager || (isBuyer && me?.company)
      //   me?.is_admin || isVendor || isManager || isBuyer
      //     ? [
      //         {
      //           id: fm('TENDER.BUTTON.ADD'),
      //           handle: () => history.push(`/orders/create`),
      //         },
      //       ]
      //     : undefined,
    });
  } else {
    setLayoutSubheader({
      title: fm('MENU.TENDERS'),
      // id: isVendor ? 'SUBMENU.TENDER.VENDOR' : 'SUBMENU.ALLORDERS',
      // btns:
      //   // me?.is_admin || (isVendor && me?.company) || isManager || (isBuyer && me?.company)
      //   me?.is_admin || isVendor || isManager || isBuyer
      //     ? [
      //         {
      //           id: fm('TENDER.BUTTON.ADD'),
      //           handle: () => history.push(`/orders/create`),
      //         },
      //       ]
      //     : undefined,
    });
  }

  // const selectStyles = {
  //   container: (provided: any) => ({
  //     ...provided,
  //     marginBottom: 16,
  //     width: '100%',
  //   }),
  //   menu: (provided: any, state: any) => ({
  //     ...provided,
  //     zIndex: 999,
  //     display: state.selectProps.isSearchable ? provided.display : 'none',
  //   }),
  //   control: (provided: any, state: any) => ({
  //     ...provided,
  //     borderColor: state.isFocused ? '#223D85FF' : '',
  //     backgroundColor: '#f5f8fa',
  //     border: 'none !important',
  //     opacity: state.isDisabled ? 0.5 : 1,
  //   }),
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? '#223D85FF' : '#fff',
  //   }),
  //   singleValue: (provided: any) => ({
  //     ...provided,
  //     color: '#4c4c4c',
  //     fontFamily: 'Jost, sans-serif',
  //     fontWeight: 600,
  //   }),
  //   placeholder: (provided: any) => ({
  //     ...provided,
  //     fontFamily: 'Jost, sans-serif',
  //     fontWeight: 600,
  //   }),
  //   dropdownIndicator: (provided: any, state: any) => {
  //     return {
  //       ...provided,
  //       display: state.selectProps.isSearchable ? provided.display : 'none',
  //     };
  //   },
  // };

  if (loading || meLoading) return <Preloader color='#ffffff' />;

  if (isVendor && !me?.company)
    return (
      <div className={classes.container}>
        <div className={`${classes.ordersCol} full`}>
          <div className={classes.card}>
            <Typography component='h5' variant='h5' className={classes.noCompanyStyle}>
              {intl.formatMessage({ id: 'YOU_HAVE_NO_COMPANY' })}
            </Typography>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {showModalMessages && (
        <MessagesDialog
          open={showModalMessages}
          handleClose={closeMessages}
          currentResponse={currentResponse}
        />
      )}

      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'TENDER.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearErrors();
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />

      <div className={classes.container}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => navigate(`/tenders/create`)}
          style={{ marginBottom: 24 }}
        >
          {intl.formatMessage({ id: 'TENDER.BUTTON.ADD' })}
        </Button>
        <div className={`${classes.ordersCol} full`}>
          {loading ? (
            <Preloader />
          ) : (
            <>
              {!tenders || !tenders.length ? (
                <Typography className={classes.noCompanyStyle} component='h5' variant='h5'>
                  {intl.formatMessage({ id: 'TENDER.LIST.EMPTY' })}
                </Typography>
              ) : (
                <div className={classes.card}>
                  <CardContent
                    className={homeClasses.classes.tableContainer}
                    style={{ height: '100%' }}
                  >
                    <Table aria-label='simple table' style={{ height: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'TENDER.TABLE.DATE' })}
                          </SHeadTableCell>

                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'CATEGORIES.TABLE.NAME' })}
                          </SHeadTableCell>

                          {!isVendor && (
                            <SHeadTableCell>
                              {intl.formatMessage({ id: 'TENDER.TAB.INSURER' })}
                            </SHeadTableCell>
                          )}

                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'TENDER.TABLE.CLIENT' })}
                          </SHeadTableCell>

                          {/* {(isVendor || me?.is_admin || isManager) && (
                          <SHeadTableCell>
                      {intl.formatMessage({ id: 'TENDER.PROPO.FILLING' })}
                            </SHeadTableCell>
                        )} */}

                          {/* <SHeadTableCell>
                            {intl.formatMessage({ id: 'TENDER.TABLE.STATUS' })}
                          </SHeadTableCell> */}

                          <SHeadTableCell>
                            {intl.formatMessage({ id: 'TENDER.TABLE.ACTIONS' })}
                          </SHeadTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tenders.map(item => (
                          <TableRow key={item.id}>
                            <TableCell>{moment(item.created_at).format('DD.MM.YY')}</TableCell>

                            <TableCell>{item.name}</TableCell>

                            {!isVendor && (
                              <TableCell>
                                {item.client?.name || item.client?.brand || '-'}
                              </TableCell>
                            )}

                            <TableCell>
                              {item.customer?.name || item.customer?.brand || '-'}
                            </TableCell>

                            {/*  {(isVendor || me?.is_admin || isManager) && (
                              <TableCell>
                                {item.insurance_percent ? `${item.insurance_percent}%` : ''}
                              </TableCell>
                            )} */}

                            {/* <TableCell>
                              {selectedId !== item.id ? (
                                <ColorButton
                                  color='#c8cee0'
                                  onClick={() => selectAction(item.id)}
                                  disable={!!(isBuyer || isVendor)}
                                >
                                  {translates[item.status]}
                                </ColorButton>
                              ) : (
                                <TextField
                                  select
                                  margin='normal'
                                  value={item.status}
                                  onChange={event => {
                                    editStatus({
                                      id: item.id,
                                      page,
                                      perPage,
                                      statuses: getStatusString(type, selectedType),
                                      data: {
                                        status: event.target.value,
                                        company_id: item.client?.id,
                                      },
                                      userId,
                                    });
                                    selectAction(-1);
                                  }}
                                  variant='outlined'
                                  className={classes.typeList}
                                  size='small'
                                >
                                  {orderTypes &&
                                    orderTypes.map(option => (
                                      <MenuItem key={option.type} value={option.type}>
                                        {option.translate}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            </TableCell> */}

                            <TableCell>
                              {/* {!smallSendMsgBtn && (
                                  <Tooltip
                                    title={intl.formatMessage({ id: 'DIALOGCHAT.SEND' })}
                                  >
                                    <Button
                                      onClick={() => handlerShowMessages(item)}
                                      color='inherit'
                                      variant='contained'
                                      className={classes.viewButton}
                                    >
                                      {intl.formatMessage({ id: 'DIALOGCHAT.SEND' })}
                                      <SendIcon
                                        color='primary'
                                        className={classes.iconViewButton}
                                      />
                                    </Button>
                                  </Tooltip>
                                )}

                                {smallSendMsgBtn && (
                                  <IconButton onClick={() => handlerShowMessages(item)}>
                                    <SendIcon color='primary' />
                                  </IconButton>
                                )} */}
                              {isBuyer && me?.company?.id !== item.client?.id && (
                                <IconButton onClick={() => editAction(item, ViewMode.VIEW)}>
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              )}

                              {(!isBuyer ||
                                (isBuyer && me?.company?.id === item.client?.id)) && (
                                <div>
                                  <IconButton onClick={() => editAction(item, ViewMode.EDIT)}>
                                    <EditIcon color='primary' />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      handleDeleteDialog(item.id);
                                    }}
                                  >
                                    <DeleteIcon color='error' />
                                  </IconButton>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePaginator
                            statuses={getStatusString(type, '')}
                            // statuses={getStatusString(type, selectedType)}
                            page={page}
                            realPerPage={tenders.length}
                            perPage={perPage}
                            total={total}
                            fetchRows={(props: any) =>
                              fetch({
                                ...props,
                                companyId:
                                  (isVendor || (isBuyer && type === ListType.MY)) &&
                                  me?.company &&
                                  me.company.id,
                              })
                            }
                            label={intl.formatMessage({ id: 'TENDER.TABLE.PERPAGE' })}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </CardContent>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    meLoading: state.profile.loading,
    tenders: state.tenders.tenders,
    loading: state.tenders.loading,
    page: state.tenders.page,
    perPage: state.tenders.per_page,
    total: state.tenders.total,
    delError: state.tenders.delError,
    userRole: state.auth.user?.roles,
    me: state.profile.me,
  }),
  {
    fetch: tendersActions.fetchRequest,
    delOrder: tendersActions.delRequest,
    clearErrors: tendersActions.clearEdit,
    editStatus: tendersActions.editStatusRequest,
    clearOrders: tendersActions.clearOrders,

    clearMessages: messsageActions.clear,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TendersList);
