import { LayoutFooter, LayoutSubheader } from '../../_base';
import { IBreadcrumb } from '../interfaces/breadcrumb';

interface IProps {
  title?: string;
  breadcrumb?: IBreadcrumb[] | undefined;
  description?: string;
  back?: boolean;
  show?: boolean;
  onPress?: () => void;
  btnTitle?: string;
  textBtnAddCart?: string;
  btnAddCart?: () => void;
}

export const setLayoutSubheader = ({
  title = '',
  breadcrumb = [],
  description = '',
  back,
  show = true,
  onPress,
  btnTitle,
  textBtnAddCart,
  btnAddCart,
}: IProps) => {
  LayoutSubheader({
    title,
    breadcrumb,
    description,
    back,
    show,
    onPress,
    btnTitle,
    textBtnAddCart,
    btnAddCart,
  });
};

export const setLayoutFooter = ({ show = true }: IProps) => {
  return LayoutFooter({ show });
};
