import { ICompany } from '../../../../interfaces/companies';

export const boxberry: ICompany = {
  id: -2,
  name: 'Доставка Боксберри',
  phone_number: '',
  brand: '',
  inn: '',
  site: '',
  managers: [],
  description: '',
  logo: '',
  favorite: true,
  rating: null,
  ogrn: '',
  kpp: '',
  okpo: '',
  okved: '',
  current_tariff: undefined,
};
