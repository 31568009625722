import React from 'react';
import { IconButton, MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { API_DOMAIN } from '../../../../constants';

import { ColorButton } from '../../../../components/other/Buttons';
import { IOrderType } from '../../../../interfaces/order';
import { useStyles } from '../../orders/hooks/useStyles';
import { IRequest } from '../../../../interfaces/request';
import { toAbsoluteUrl } from '../../../../../_base';

type TProps = {
  item: IRequest;
  selectAction: (id: any) => void;
  editStatus: (data: { id: number; status: string }) => void;
  handleDeleteDialog: (id: number | undefined) => void;
  editAction: (item: IRequest) => void;
  selectedId: number;
  isBuyer: boolean;
  translates: { [p: string]: string };
  requestTypes: IOrderType[];
  viewAction: (item: IRequest) => void;
};
const TableRowRequestList: React.FC<TProps> = ({
  item,
  selectAction,
  handleDeleteDialog,
  editAction,
  selectedId,
  isBuyer,
  translates,
  editStatus,
  requestTypes,
  viewAction,
}) => {
  const classes1 = useStyles();
  const { classes } = classes1;

  // const isOpen = me?.is_admin && (item?.orders?.length ?? 0) > 1;

  return (
    <>
      <TableRow key={item.id}>
        <TableCell align='center' className={classes.cell}>
          {item.id || '-'}{' '}
        </TableCell>
        <TableCell align='center' className={classes.cell}>
          <img
            src={
              item.photos && item.photos[0]?.small
                ? `${API_DOMAIN}/${item.photos[0]?.small}`
                : toAbsoluteUrl('/images/placeholder.png')
            }
            className={classes.img}
            alt=''
          />
        </TableCell>
        <TableCell className={classes.cell}>{item.title || '-'}</TableCell>

        <TableCell align='center' className={classes.cell}>
          {item.date ? moment(item.date).format('DD.MM.YY') : '-'}
        </TableCell>

        <TableCell align='center' className={classes.cell}>
          {selectedId !== item.id ? (
            <ColorButton
              color='#DDDDDD'
              onClick={() => selectAction(item.id)}
              disable={Boolean(isBuyer)}
            >
              {translates[item.status]}
            </ColorButton>
          ) : (
            <TextField
              select
              margin='normal'
              value={item.status}
              onChange={event => {
                editStatus({
                  id: item.id,
                  status: event.target.value,
                });
                selectAction(-1);
              }}
              variant='outlined'
              className={classes.typeList}
              size='small'
            >
              {requestTypes &&
                requestTypes.map(option => (
                  <MenuItem key={option.type} value={option.type}>
                    {option.translate}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </TableCell>

        <TableCell align='center' className={classes.cell}>
          {item.user.fio}
        </TableCell>

        <TableCell align='center' className={classes.cell}>
          {item.user.phoneNumbers || '-'}
        </TableCell>

        <TableCell align='center' className={classes.cell}>
          {item.user.email || '-'}
        </TableCell>
        <TableCell align='right' className={classes.cell}>
          <IconButton
            onClick={() => {
              viewAction(item);
            }}
          >
            <VisibilityIcon color='primary' />
          </IconButton>

          {!isBuyer && (
            <>
              <IconButton
                color='primary'
                aria-label='previous'
                onClick={() => {
                  editAction(item);
                }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleDeleteDialog(item.id);
                }}
              >
                <DeleteIcon color='error' />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(TableRowRequestList);
