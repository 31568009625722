import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import TypeForm from './components/types/TypeForm';

import { actions as productTypesActions } from '../../../store/ducks/productType.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { IEnumValue, ITypeParameter } from '../../../interfaces/productType';
import useSnackbarProductType from './hooks/useSnackbarProductType';
import { useFormatMessage } from '../../../hooks';
import { useGetGroups } from '../groups/hooks/useGetGroups';
import { useAddIconParam } from './hooks/useAddIconParam';
import { useDeleteEnumValue } from './hooks/useDeleteEnumValue';

const ProductTypePage: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { typeId } = useParams();
  const [fetchGroups, loadingGroups, groups] = useGetGroups();
  const {
    type,
    editSuccess,
    editParamSuccess,
    editError,
    editParamError,
    loadingPositions,
    errorPositions,
    successPositions,
    editParameterLoading,
    editLoading,
  } = useSelector(
    ({ productTypes }: IAppState) => ({
      type: productTypes.productType,
      loading: productTypes.byIdLoading,
      editSuccess: productTypes.editSuccess,
      editParamSuccess: productTypes.editParameterSuccess,
      editError: productTypes.editError,
      editParamError: productTypes.editParameterError,
      loadingPositions: productTypes.setPositionLoading,
      errorPositions: productTypes.setPositionError,
      successPositions: productTypes.setPositionSuccess,
      editParameterLoading: productTypes.editParameterLoading,
      editLoading: productTypes.editLoading,
    }),
    shallowEqual
  );
  const [isAlertOpen, setAlertOpen] = useState(false);

  const fm = useFormatMessage();
  const [id, setId] = useState<number | undefined>(typeId ? +typeId : undefined);
  const navigate = useNavigate();
  const { fetchDelete: fetchDeleteEnumValue } = useDeleteEnumValue(
    () => id && dispatch(productTypesActions.fetchByIdRequest(id))
  );
  const { loadingUploadIcons, fetchDeleteIcon } = useAddIconParam(
    () => id && dispatch(productTypesActions.fetchByIdRequest(id))
  );

  useSnackbarProductType({
    editSuccess,
    editError,
    editParamError,
    editParamSuccess,
    id,
    type,
    clearError: () => {
      dispatch(productTypesActions.setPositionsClear());
      dispatch(productTypesActions.clearEdit());
    },
    setId,
    errorPositions,
  });

  useLayoutEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (errorPositions || successPositions) {
      errorPositions && enqueueSnackbar(errorPositions, { variant: 'error' });
      successPositions &&
        enqueueSnackbar(fm('PRODUCT.TYPE.POSITIONS.SUCCESS'), { variant: 'success' });
      dispatch(productTypesActions.setPositionsClear());
    }
  }, [errorPositions, successPositions]);

  useEffect(() => {
    if (id) {
      dispatch(productTypesActions.fetchByIdRequest(id));
    }
    return () => {
      dispatch(productTypesActions.setPositionsClear());
      dispatch(productTypesActions.clearEdit());
    };
  }, [id]);

  useEffect(() => {
    if ((editSuccess || editParamSuccess) && id)
      dispatch(productTypesActions.fetchByIdRequest(id));
  }, [editSuccess, editParamSuccess, id]);

  setLayoutSubheader({
    title: `${id ? `${fm('CATEGORY.TITLE.EDIT')}` : `${fm('CATEGORY.TITLE.ADD')}`} ${fm(
      'PRODUCT.TYPES.SUBTITLE'
    )} `,
  });
  setLayoutFooter({ show: true });

  const editAction = useCallback(
    (values: any) => {
      if (id) {
        dispatch(productTypesActions.editRequest({ id, data: values }));
      } else {
        dispatch(productTypesActions.addRequest({ data: values }));
      }
    },
    [id]
  );

  const deleteImage = useCallback((id: number) => {
    const data = new FormData();
    data.append('delete', 'true');
    fetchDeleteIcon(id, data);
  }, []);

  const editParamAction = useCallback(
    (values: ITypeParameter, editEnums?: IEnumValue[], newEnums?: IEnumValue[]) => {
      if (values.id) {
        dispatch(
          productTypesActions.editParameterRequest({
            parameterId: values.id,
            data: values,
            editEnums,
            newEnums,
          })
        );
      } else if (id) {
        dispatch(
          productTypesActions.addParameterRequest({
            typeId: id,
            data: values,
            editEnums,
            newEnums,
          })
        );
      }
    },
    [id]
  );

  const setPositions = useCallback(
    (positions: string) => {
      id && dispatch(productTypesActions.setPositionsRequest({ positions, id }));
    },
    [id]
  );

  const handleDelProductType = () => {
    setAlertOpen(true);
  };

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    if (id) {
      dispatch(productTypesActions.delRequest({ id }));
      navigate('/products/types/list');
    }
  }, [id, navigate, setAlertOpen]);

  if (loadingGroups) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={fm('PRODUCT.TYPES.DELETE.TEXT')}
        okText={fm('CATEGORIES.DELETE.OK')}
        cancelText={fm('CATEGORIES.DELETE.CANCEL')}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={deleteAction}
      />
      <TypeForm
        type={id ? type : undefined}
        typeId={id || undefined}
        typeAction={editAction}
        paramAction={editParamAction}
        delProductType={handleDelProductType}
        setPositions={setPositions}
        disabled={loadingPositions || loadingUploadIcons || editParameterLoading}
        groups={groups}
        deleteImage={deleteImage}
        fetchDeleteEnumValue={fetchDeleteEnumValue}
        editLoading={editLoading}
      />
    </>
  );
};

export default ProductTypePage;
