import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';

import { IAppState } from '../../../../store/rootDuck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';

import Preloader from '../../../../components/other/Preloader/Preloader';
import CompaniesTable from '../../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../../interfaces/user';

const useStyles = makeStyles(() =>
  createStyles({
    emptyPaper: {
      minHeight: 400,
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface IProps {
  me: IUser;
}

const Companies: React.FC<TPropsFromRedux & IProps> = ({
  total,
  page,
  perPage,
  fetchCompanies,
  loading,
  companies,
  me,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchCompanies({ page, perPage });
  }, []);

  if (loading || !me)
    return (
      <div className={classes.emptyPaper}>
        <Preloader />
      </div>
    );

  return (
    <CompaniesTable
      me={me}
      page={page}
      perPage={perPage}
      total={total}
      companies={companies}
    />
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.companies.page,
    perPage: state.companies.per_page,
    total: state.companies.total,
    companies: state.companies.companies,
    loading: state.companies.loading,
  }),
  {
    fetchCompanies: companiesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Companies);
