import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormatMessage } from '../../../../hooks';
import { ITender } from '../../../../interfaces/order';
import { IUser } from '../../../../interfaces/user';
import { actions as tendersActions } from '../../../../store/ducks/tenders.duck';

const getInitialValues = (tender?: ITender) => {
  const values: any = {
    name: tender?.name || '',
    fio: tender?.fio || '',
    territory: tender?.territory || '',
    technological_description: tender?.technological_description || '',
    price: tender?.price || 0,
    implementation: tender?.implementation || '',
    phone: tender?.phone || '',
    comment: tender?.comment || '',
    status: tender?.status || 'new',
    period_type: tender?.period_type || 'interval',
    start: tender?.start_date || null,
    end: tender?.end_date || null,
    months_years: tender?.months_years || 0,
    contractor: tender?.contractor || '',
    company: tender?.client || null,
    companyInn: '',
    noReportRequired: tender?.no_report_required || false,
    rate: tender?.rate || null,
    franchise: tender?.franchise || null,
    proportionally: tender?.proportionally ? 'prop' : 'no_prop',
    type: tender?.type || '',
    insuranceAmount: tender?.insurance_amount || null,
    publicProposals: tender?.public_proposals || null,
    companyInterested: tender?.interested || null,
    companyInnInterested: '',
    categoryId: tender?.category?.id,
    // || parentCategoryId,
    // projectMilestones: tender?.project_milestones || '',
    // productionMajorWorks: tender?.production_major_works || '',
    customer: tender?.customer,
    companyInnCustomer: '',
    dopContractor: tender?.dop_contractor,
    companyInnDopContracror: '',

    // items: order.items,
  };
  // if (order.items) {
  //   order.items.forEach(item => {
  //     values[`item${item.id}`] = item;
  //   });
  // }
  return values;
};

export const useFormikTenderForm = (
  id: string,
  createTender: (data: any) => void,
  tender?: ITender,
  me?: IUser
) => {
  const fm = useFormatMessage();
  const dispatch = useDispatch();
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: getInitialValues(tender),
    validationSchema: Yup.object().shape({
      name: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).trim(),
      company: me?.company
        ? undefined
        : Yup.object()
            .shape({
              id: Yup.number(),
            })
            .nullable()
            .required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
    }),

    onSubmit: submitValues => {
      const data = { ...submitValues };
      data.company_id = submitValues.company?.id;
      data.interested_id = submitValues.companyInterested?.id;
      data.customer_id = submitValues.customer?.id;
      data.dop_contractor_id = submitValues.dopContractor?.id;
      delete data.company;
      delete data.companyInn;
      delete data.companyInterested;
      delete data.companyInnInterested;
      delete data.customer;
      delete data.dopContractor;
      delete data.companyInnCustomer;
      delete data.companyInnDopContracror;
      if (!submitValues.price) {
        data.price = null;
      }
      if (!submitValues.rate) {
        data.rate = null;
      }
      if (!submitValues.franchise) {
        data.franchise = null;
      }
      if (!submitValues.insuranceAmount) {
        data.insuranceAmount = null;
      }
      if (submitValues.categoryId) {
        data.category_id = submitValues.categoryId;
      }
      data.proportionally = submitValues.proportionally === 'prop';
      if (id) {
        dispatch(tendersActions.editRequest({ id: +id, data }));
      } else {
        createTender(data);
      }
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    setValues(getInitialValues(tender));
  }, [tender, setValues]);

  return formik;
};
