import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { IOrderForEdit, IParticipation, ITender } from '../../interfaces/order';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import {
  getTenders,
  editTender,
  deleteTender,
  getTenderById,
  getParticipations,
} from '../../crud/orders.crud';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'tenders/FETCH_REQUEST';
const FETCH_SUCCESS = 'tenders/FETCH_SUCCESS';
const FETCH_FAIL = 'tenders/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'tenders/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'tenders/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'tenders/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'tenders/CLEAR_EDIT';
const EDIT_REQUEST = 'tenders/EDIT_REQUEST';
const EDIT_SUCCESS = 'tenders/EDIT_SUCCESS';
const EDIT_FAIL = 'tenders/EDIT_FAIL';

const EDIT_STATUS_REQUEST = 'tenders/EDIT_STATUS_REQUEST';
const EDIT_STATUS_SUCCESS = 'tenders/EDIT_STATUS_SUCCESS';
const EDIT_STATUS_FAIL = 'tenders/EDIT_STATUS_FAIL';

const DELETE_REQUEST = 'tenders/DELETE_REQUEST';
const DELETE_SUCCESS = 'tenders/DELETE_SUCCESS';
const DELETE_FAIL = 'tenders/DELETE_FAIL';
const FETCH_PARTICIPATION_REQUEST = 'tenders/FETCH_PARTICIPATION_REQUEST';
const FETCH_PARTICIPATION_SUCCESS = 'tenders/FETCH_PARTICIPATION_SUCCESS';
const FETCH_PARTICIPATION_FAIL = 'tenders/FETCH_PARTICIPATION_FAIL';
const CLEAR_PARTICIPATION = 'tenders/CLEAR_PARTICIPATION';
const CLEAR_ORDERS = 'tenders/CLEAR_ORDERS';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  tenders: ITender[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  tender: ITender | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delError: string | null;
  pagePart: number;
  perPagePart: number;
  totalPart: number;
  participations?: IParticipation[];
  loadingPart: boolean;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  tenders: undefined,
  loading: false,
  success: false,
  error: null,

  tender: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delError: null,

  pagePart: 1,
  perPagePart: 20,
  totalPart: 0,
  participations: undefined,
  loadingPart: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          tenders: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          tenders: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case CLEAR_ORDERS: {
        return {
          ...state,
          tenders: undefined,
          loading: false,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          tender: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          tender: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
          delError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, delError: action.payload };
      }

      case EDIT_STATUS_REQUEST: {
        return { ...state, loading: true };
      }
      case EDIT_STATUS_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }
      case FETCH_PARTICIPATION_REQUEST: {
        return {
          ...state,
          participations: undefined,
          loadingPart: true,
        };
      }
      case FETCH_PARTICIPATION_SUCCESS: {
        return {
          ...state,
          pagePart: action.payload.page,
          perPagePart: action.payload.per_page,
          totalPart: action.payload.total,
          participations: action.payload.data,
          loadingPart: false,
        };
      }

      case FETCH_PARTICIPATION_FAIL: {
        return { ...state, loadingPart: false };
      }

      case CLEAR_PARTICIPATION: {
        return {
          ...state,
          loadingPart: false,
          pagePart: 1,
          perPagePart: 20,
          total: 0,
          participations: undefined,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    statuses: string;
    userId?: number;
    companyId?: number;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ITender[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ITender>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: any }) => createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  editStatusRequest: (payload: {
    id: number;
    data: IOrderForEdit;
    statuses: string;
    page: number;
    perPage: number;
    userId?: number;
  }) => createAction(EDIT_STATUS_REQUEST, payload),
  editStatusSuccess: () => createAction(EDIT_STATUS_SUCCESS),
  editStatuFail: (payload: string) => createAction(EDIT_STATUS_FAIL, payload),

  delRequest: (payload: {
    id: number;
    statuses: string;
    page: number;
    perPage: number;
    companyId?: number;
  }) => createAction(DELETE_REQUEST, payload),
  delSuccess: () => createAction(DELETE_SUCCESS),
  delFail: (payload: string) => createAction(DELETE_FAIL, payload),
  fetchParticipation: (payload: {
    tender_id: number;
    page: number;
    per_page: number;
    company_id?: number;
    statuses?: string;
  }) => createAction(FETCH_PARTICIPATION_REQUEST, payload),
  fetchPartSuccess: (payload: IServerResponse<IParticipation[]>) =>
    createAction(FETCH_PARTICIPATION_SUCCESS, payload),
  fetchPartFail: (payload: string) => createAction(FETCH_PARTICIPATION_FAIL, payload),
  clearPart: () => createAction(CLEAR_PARTICIPATION),
  clearOrders: () => createAction(CLEAR_ORDERS),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    statuses: string;
    userId?: number;
    companyId?: number;
  };
}) {
  try {
    const { data }: { data: IServerResponse<ITender[]> } = yield call(() =>
      getTenders(payload.statuses, payload.page, payload.perPage, payload.companyId)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* delSaga({
  payload,
}: {
  payload: { id: number; statuses: string; page: number; perPage: number; companyId?: number };
}) {
  try {
    yield call(() => deleteTender(payload.id));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        statuses: payload.statuses,
        companyId: payload.companyId,
      })
    );
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

function* editStatusSaga({
  payload,
}: {
  payload: {
    id: number;
    statuses: string;
    page: number;
    perPage: number;
    data: IOrderForEdit;
    userId?: number;
  };
}) {
  try {
    yield call(() => editTender(payload.id, payload.data));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        statuses: payload.statuses,
        userId: payload.userId,
      })
    );
  } catch (e) {
    yield put(actions.editStatuFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ITender> } = yield call(() =>
      getTenderById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload: { id, data } }: { payload: { id: number; data: any } }) {
  try {
    yield call(() => editTender(id, data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* fetchPartSaga({
  payload,
}: {
  payload: {
    tender_id: number;
    page: number;
    per_page: number;
    company_id?: number;
    statuses?: string;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IParticipation[]> } = yield call(() =>
      getParticipations(
        payload.tender_id,
        payload.page,
        payload.per_page,
        payload.company_id,
        payload.statuses
      )
    );
    yield put(actions.fetchPartSuccess(data));
  } catch (e) {
    yield put(actions.fetchPartFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DELETE_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.editStatusRequest>>(
    EDIT_STATUS_REQUEST,
    editStatusSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.fetchParticipation>>(
    FETCH_PARTICIPATION_REQUEST,
    fetchPartSaga
  );
}
