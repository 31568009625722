import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { injectIntl, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';

import { getDoc, createDoc, editDoc } from '../../../crud/docs.crud';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { getDocFromPathname } from './utils';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { IAppState } from '../../../store/rootDuck';
import { categoryLanguages } from '../categories/utils/categoryLanguages';
import CkEditorCustom from '../../../components/formComponents/CkEditorCustom';

const useStyles = makeStyles((theme: any) => ({
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  editor: {
    minHeight: 400,
  },
  cardEdit: {
    backgroundColor: '#ffff',
    width: 'calc((100% - 24px) / 2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formEdit: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

interface IDocument {
  code: string;
  name: string;
  text: string;
  public: boolean;
  id?: number;
  locale?: string;
}

function UserDocPage({ me }: TPropsFromRedux) {
  const classes = useStyles();
  const intl = useIntl();
  const fm = useFormatMessage();
  const [loading, setLoading] = useState(false);
  const [docLanguage, setLanguage] = useState(intl.locale === 'en' ? 'ru' : 'en');
  const [userDocument, setDocument] = useState<IDocument | null>(null);
  const [userDocumentLocale, setDocumentLocale] = useState<IDocument | null>(null);
  const [editorState, setEditorState] = useState<any>(null);
  const [editorStateLocale, setEditorStateLocale] = useState<any>(null);
  const { type } = useParams();
  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const document = getDocFromPathname(type || '', intl);

  setLayoutSubheader({
    title: document.name,
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    setLoading(true);
    getDoc(document.code, intl.locale)
      .then(({ data: requestData }) => {
        setDocument(requestData.data);
        setLoading(false);
      })
      .catch(() => {
        setDocument(null);
        setLoading(false);
      });
  }, [type, document.code]);

  useEffect(() => {
    setLoading(true);
    getDoc(document.code, docLanguage)
      .then(({ data: requestData }) => {
        setDocumentLocale(requestData.data);
        setLoading(false);
      })
      .catch(() => {
        setDocumentLocale(null);
        setLoading(false);
      });
  }, [type, document.code, docLanguage]);

  const handleUnLoading = () => setLoading(false);

  const handleSubmit = async () => {
    const dataCurrent: IDocument = {
      code: document.code,
      name: document.name,
      text: editorState?.getData() || '',
      public: true,
      locale: intl.locale,
    };
    const dataLang: IDocument = {
      code: document.code,
      name: document.name,
      text: editorStateLocale?.getData() || '',
      public: true,
      locale: docLanguage,
    };
    setLoading(true);

    if (!userDocument) {
      createDoc(dataCurrent).then(handleUnLoading).catch(handleUnLoading);
    } else {
      editDoc(userDocument.id, dataCurrent).then(handleUnLoading).catch(handleUnLoading);
    }
    if (!userDocumentLocale) {
      createDoc(dataLang).then(handleUnLoading).catch(handleUnLoading);
    } else {
      editDoc(userDocumentLocale.id, dataLang).then(handleUnLoading).catch(handleUnLoading);
    }
  };

  return (
    <>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {() => (
          <Form
          // placeholder={undefined}
          // onPointerEnterCapture={undefined}
          // onPointerLeaveCapture={undefined}
          >
            <div className={classes.formEdit}>
              <div className={classes.cardEdit}>
                <div style={{ padding: 16 }}>
                  <TextField
                    select
                    margin='normal'
                    label={fm('MAIN.LANGUAGE')}
                    value={intl.locale}
                    variant='outlined'
                    disabled
                  >
                    <MenuItem value={intl.locale}>
                      {fm(`CATEGORY.${intl.locale.toUpperCase()}.LANGUAGE`)}
                    </MenuItem>
                  </TextField>
                  <CkEditorCustom
                    disabled={!!isRoleManager}
                    html={userDocument?.text || ''}
                    setEditorStateCallback={setEditorState}
                  />
                </div>
              </div>
              <div className={classes.cardEdit}>
                <div style={{ padding: 16 }}>
                  <TextField
                    select
                    margin='normal'
                    label={fm('AUTH.INPUT.LANG')}
                    name='language'
                    value={docLanguage}
                    onChange={e => setLanguage(e.target.value)}
                    variant='outlined'
                  >
                    {categoryLanguages
                      .filter(lang => lang.code !== intl.locale)
                      .map(lang => (
                        <MenuItem key={lang.code} value={lang.code}>
                          {fm(`CATEGORY.${lang.code.toUpperCase()}.LANGUAGE`)}
                        </MenuItem>
                      ))}
                  </TextField>
                  <CkEditorCustom
                    disabled={!!isRoleManager}
                    html={userDocumentLocale?.text || ''}
                    setEditorStateCallback={setEditorStateLocale}
                  />
                </div>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              {isRoleAdmin && (
                <ButtonWithLoader onPress={handleSubmit} loading={loading}>
                  {userDocument
                    ? intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })
                    : intl.formatMessage({ id: 'COMMON.BUTTON.CREATE' })}
                </ButtonWithLoader>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const connector = connect((state: IAppState) => ({
  me: state.profile.me,
}));
type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(UserDocPage));
