import React from 'react';
import {
  MenuItem,
  Typography,
  // InputAdornment,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'react-intl';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import csLocale from 'date-fns/locale/cs';
// import NumberFormatCustom from '../../../../components/formComponents/NumberFormatCustom';
import { IOrderType, ITender } from '../../../../interfaces/order';
import { useStylesModalAddParticipation } from '../hooks/useStyles';
// import CreateRiskToTender from '../../products/components/CreateRiskToTender';
import { IUser } from '../../../../interfaces/user';
import { IProduct } from '../../../../interfaces/product';
// import TenderFormRisks from './TenderFormRisks';
import TenderCompanyForm from './TenderCompanyForm';
// import TenderInterestedCompanyForm from './TenderInterestedCompanyForm';
import TenderClientCompanyForm from './TenderClientCompanyForm';
// import TenderContractorCompanyForm from './TenderContractorCompanyForm';
// import { ReactComponent as IconCircleCalendar } from '../../../../../_base/layout/assets/layout-svg-icons/iconCircleCalend.svg';
// import { ReactComponent as IconCirclePlus } from '../../../../../_base/layout/assets/layout-svg-icons/iconCirclePlus.svg';
// import NumberFormatPrice from '../../../../components/NumberFormatPrice';
// import TextFieldCustom from '../../../../components/TextFieldCustom';
import { createChildrenList } from '../../../../utils/createParentList';
import { ICategoryItem } from '../../categories/interfaces';

const locales: { [key: string]: any } = {
  ru: ruLocale,
  en: enLocale,
  cz: csLocale,
};

interface IProps {
  formik: any;
  disabled: boolean;
  tenderTypes: IOrderType[];
  products: IProduct[];
  tender?: ITender;
  me: IUser | undefined;
  addRiskToTender: (data: { data: FormData }) => void;
  deleteRiskCommonTab: (id: number) => void;
  id: string;
  showInsurerCompany: boolean;
  showInsuredCompany: boolean;
  successCreate?: boolean;
  categories?: ICategoryItem[];
}

const TenderForm: React.FC<IProps> = ({
  formik,
  disabled,
  // products,
  // addRiskToTender,
  tender,
  // deleteRiskCommonTab,
  id,
  showInsurerCompany,
  showInsuredCompany,
  // successCreate,
  categories,
}) => {
  const intl = useIntl();
  const classes1 = useStylesModalAddParticipation();
  const { classes } = classes1;
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = formik;

  return (
    <>
      <div className={classes.card}>
        <Typography
          component='h6'
          variant='h6'
          className={classes.typography}
          style={{ marginTop: 20 }}
        >
          {intl.formatMessage({ id: 'TENDER.PROJECT' })}
        </Typography>
        <TextField
          label={intl.formatMessage({ id: 'TENDER.TABLE.NAME' })}
          autoComplete='off'
          type='text'
          margin='normal'
          value={values.name}
          name='name'
          variant='outlined'
          helperText={touched.name && errors.name}
          error={Boolean(touched.name && errors.name)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          // InputProps={{
          //   classes: {
          //     root: classes.input,
          //     focused: classes.inputFocused,
          //     notchedOutline: classes.notchedOutline,
          //   },
          // }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.labelTextField,
          //     focused: classes.labelTextFieldFocused,
          //     shrink: classes.labelTextFieldShrink,
          //   },
          // }}
        />
        <TextField
          label={intl.formatMessage({ id: 'TENDER.TABLE.TERRITORY' })}
          autoComplete='off'
          type='text'
          margin='normal'
          value={values.territory}
          name='territory'
          variant='outlined'
          helperText={touched.territory && errors.territory}
          error={Boolean(touched.territory && errors.territory)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          // InputProps={{
          //   classes: {
          //     root: classes.input,
          //     focused: classes.inputFocused,
          //     notchedOutline: classes.notchedOutline,
          //   },
          // }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.labelTextField,
          //     focused: classes.labelTextFieldFocused,
          //     shrink: classes.labelTextFieldShrink,
          //   },
          // }}
        />
        <TextField
          autoComplete='off'
          label={intl.formatMessage({ id: 'TENDER.TABLE.TECH' })}
          type='text'
          margin='normal'
          value={values.technological_description}
          name='technological_description'
          variant='outlined'
          helperText={touched.technological_description && errors.technological_description}
          error={Boolean(
            touched.technological_description && errors.technological_description
          )}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          multiline
          rows={4}
          InputLabelProps={{ shrink: true }}
          // InputProps={{
          //   classes: {
          //     root: classes.multilineInput,
          //     focused: classes.inputFocused,
          //     notchedOutline: classes.notchedOutline,
          //   },
          // }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.labelTextField,
          //     focused: classes.labelTextFieldFocused,
          //     shrink: classes.labelTextFieldShrink,
          //   },
          // }}
        />
        <TextField
          select
          label={intl.formatMessage({ id: 'TENDER.TABLE.CATEGORY' })}
          autoComplete='off'
          type='text'
          margin='normal'
          value={values.categoryId || ''}
          name='categoryId'
          variant='outlined'
          helperText={touched.category && errors.category}
          error={Boolean(touched.category && errors.category)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          // InputProps={{
          //   classes: {
          //     root: classes.input,
          //     // focused: classes.inputFocused,
          //     notchedOutline: classes.notchedOutline,
          //   },
          // }}
          // InputLabelProps={{
          //   classes: {
          //     root: classes.labelTextField,
          //     focused: classes.labelTextFieldFocused,
          //     shrink: classes.labelTextFieldShrink,
          //   },
          // }}
        >
          {categories &&
            createChildrenList(categories, '\u00B7').map(cat => (
              <MenuItem key={cat.id?.toString()} value={cat.id?.toString()}>
                {cat.name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className={classes.card}>
        <Typography
          component='h6'
          variant='h6'
          className={classes.typography}
          style={{ marginTop: 20 }}
        >
          {intl.formatMessage({ id: 'TENDER.PARTICIPANTS' })}
        </Typography>
        <div className={classes.rowFields}>
          <>
            {showInsurerCompany && (
              <TenderCompanyForm
                tenderFormik={formik}
                id={id}
                tender={tender}
                isFull={showInsurerCompany && showInsuredCompany}
              />
            )}
            <TenderClientCompanyForm
              tenderFormik={formik}
              id={id}
              tender={tender}
              isFull={showInsurerCompany && showInsuredCompany}
            />
          </>
        </div>
        {/* <div className={classes.rowFields}>
          <>
            {showInsuredCompany && (
              <TenderInterestedCompanyForm
                tenderFormik={formik}
                id={id}
                tender={tender}
                isFull={showInsurerCompany && showInsuredCompany}
              />
            )}
            <TenderContractorCompanyForm
              tenderFormik={formik}
              id={id}
              tender={tender}
              isFull={showInsurerCompany && showInsuredCompany}
            />
          </>
        </div> */}
        <div className={classes.rowFields}>
          <>
            <div style={{ width: '49%' }}>
              <TextField
                label={intl.formatMessage({ id: 'TENDER.TABLE.IMPLEMENT' })}
                autoComplete='off'
                type='text'
                margin='normal'
                value={values.implementation}
                name='implementation'
                variant='outlined'
                helperText={touched.implementation && errors.implementation}
                error={Boolean(touched.implementation && errors.implementation)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div style={{ width: '49%' }}>
              <TextField
                label={intl.formatMessage({ id: 'ORDER.TABLE.PRICE' })}
                autoComplete='off'
                type='text'
                margin='normal'
                value={values.price}
                name='price'
                variant='outlined'
                helperText={touched.price && errors.price}
                error={Boolean(touched.price && errors.price)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </>
        </div>
      </div>
      {/* {tender && (
        <div className={classes.productButtonCard}>
          <div>
            <div
              style={{
                height: 50,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography
                  component='h6'
                  variant='h6'
                  className={classes.typography}
                  style={{ marginTop: 20 }}
                >
                  {intl.formatMessage({ id: 'TENDER.TABLE.PRICE.SUM' })}
                </Typography>
              </div>
              <div
                style={{
                  marginTop: 15,
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}
              >
                <IconCirclePlus style={{ marginTop: 5 }} />
                <CreateRiskToTender
                  isCommonInformation
                  addRiskToTender={addRiskToTender}
                  smallModal
                  successCreate={successCreate}
                />
              </div>
            </div>
            <TenderFormRisks
              disabled={disabled}
              products={products}
              isCommonInformation
              deleteRiskCommonTab={deleteRiskCommonTab}
            />
          </div>
        </div>
      )} */}
      <div className={classes.card}>
        <Typography
          component='h6'
          variant='h6'
          className={classes.typography}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {intl.formatMessage({ id: 'TENDER.FORM.SUMM' })}
        </Typography>
        {values.period_type && (
          <div className={classes.rowFields} style={{ alignItems: 'center', gap: 10 }}>
            <TextField
              label={intl.formatMessage({ id: 'TENDER.PERIOD.TYPE' })}
              select
              margin='normal'
              value={values.period_type}
              onChange={handleChange}
              name='period_type'
              variant='outlined'
              helperText={touched.period_type && errors.period_type}
              error={Boolean(touched.period_type && errors.period_type)}
              disabled={disabled}
              style={{ marginRight: 15, margin: 0 }}
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                classes: {
                  root: classes.selectFocused,
                },
              }}
            >
              <MenuItem value='interval'>
                {intl.formatMessage({ id: 'TENDER.INTERVAL' })}
              </MenuItem>
              <MenuItem value='months'>{intl.formatMessage({ id: 'TENDER.MONTHS' })}</MenuItem>
              <MenuItem value='years'>{intl.formatMessage({ id: 'TENDER.YEARS' })}</MenuItem>
            </TextField>
            {values.period_type === 'interval' ? (
              <>
                <div style={{ width: '100%' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locales[intl.locale]}
                  >
                    <DatePicker
                      label={intl.formatMessage({ id: 'TENDER.DATE.START' })}
                      // id='start'
                      // variant='dialog'
                      className={classes.datePicker}
                      value={values.start ? dayjs(values.start) : null}
                      onChange={date => setFieldValue('start', dayjs(date).toString())}
                      // InputLabelProps={{ shrink: true }}
                      // error={Boolean(touched.start && errors.start)}
                      // helperText={touched.start && errors.start}
                      disabled={disabled}
                      // inputVariant='outlined'
                      // style={{ width: '100%', marginTop: 16, marginBottom: 8 }}
                      // okLabel={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
                      // cancelLabel={intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' })}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ width: '100%' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locales[intl.locale]}
                  >
                    <DatePicker
                      // id='end'
                      className={classes.datePicker}
                      label={intl.formatMessage({ id: 'TENDER.DATE.END' })}
                      // variant='dialog'
                      value={values.end ? dayjs(values.end) : null}
                      onChange={date => setFieldValue('end', dayjs(date).toString())}
                      // InputLabelProps={{ shrink: true }}
                      // error={Boolean(touched.end && errors.end)}
                      // helperText={touched.end && errors.end}
                      disabled={disabled}
                      // inputVariant='outlined'
                      // style={{ width: '96%', marginTop: 16, marginBottom: 8, marginLeft: 15 }}
                      // okLabel={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
                      // cancelLabel={intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' })}
                    />
                  </LocalizationProvider>
                </div>
              </>
            ) : (
              <>
                <TextField
                  autoComplete='off'
                  type='text'
                  label={intl.formatMessage({
                    id: values.period_type === 'years' ? 'TENDER.YEARS' : 'TENDER.MONTHS',
                  })}
                  margin='normal'
                  name='months_years'
                  value={values.months_years}
                  variant='outlined'
                  helperText={touched.months_years && errors.months_years}
                  error={Boolean(touched.months_years && errors.months_years)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
          </div>
        )}
        {/* <TextFieldCustom
          label={intl.formatMessage({ id: 'TENDER.MILESTONES' })}
          autoComplete='off'
          type='text'
          margin='normal'
          value={values.projectMilestones}
          name='projectMilestones'
          variant='outlined'
          helperText={touched.projectMilestones && errors.projectMilestones}
          error={Boolean(touched.projectMilestones && errors.projectMilestones)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          multiline
          rows={4}
          InputProps={{
            classes: {
              root: classes.multilineInput,
              focused: classes.inputFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelTextField,
              focused: classes.labelTextFieldFocused,
              shrink: classes.labelTextFieldShrink,
            },
          }}
        /> */}
        {/* <TextFieldCustom
          label={intl.formatMessage({ id: 'TENDER.MAJOR.WORKS' })}
          autoComplete='off'
          type='text'
          margin='normal'
          value={values.productionMajorWorks}
          name='productionMajorWorks'
          variant='outlined'
          helperText={touched.productionMajorWorks && errors.productionMajorWorks}
          error={Boolean(touched.productionMajorWorks && errors.productionMajorWorks)}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          multiline
          rows={4}
          InputProps={{
            classes: {
              root: classes.multilineInput,
              focused: classes.inputFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.labelTextField,
              focused: classes.labelTextFieldFocused,
              shrink: classes.labelTextFieldShrink,
            },
          }}
        /> */}
      </div>
    </>
  );
};

export default React.memo(TenderForm);
