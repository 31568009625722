import { ICategoryItem, ICategoryParent } from '../pages/home/categories/interfaces';

const addParentInList = (
  categories: ICategoryItem[] | undefined,
  list: ICategoryParent[],
  parentId: number | undefined,
  level: number,
  mark = '',
  maxLevel: number
) => {
  if (!categories || categories.length === 0) {
    return list;
  }

  categories.flatMap((category: ICategoryItem) => {
    if (level <= maxLevel) {
      if (!category.parent_id || category.parent_id !== parentId) {
        list.push({ id: category.id, name: mark.repeat(level) + category.name });
        addParentInList(category.children, list, parentId, level + 1, mark, maxLevel);
      }
    }
  });
};

export const createParentList = (
  categories: ICategoryItem[] | undefined,
  rootText: string,
  parentId: number | undefined,
  mark = '',
  maxLevel = 30
) => {
  const parents: ICategoryParent[] = [];
  parents.push({ id: -1, name: rootText });
  addParentInList(categories, parents, parentId, 1, mark, maxLevel);

  return parents;
};

const addChildrenList = (
  categories: ICategoryItem[] | undefined,
  list: ICategoryParent[],
  mark: string = '',
  counter: number = 0,
  maxLevel: number
) => {
  if (!categories || categories.length === 0) {
    return list;
  }
  const newCounter = counter + 1;
  const marks = mark ? mark.repeat(newCounter) : '';

  if (maxLevel < newCounter) {
    return;
  }

  categories.flatMap(category => {
    const name = mark ? `${marks} ${category.name}` : category.name;
    list.push({ id: category.id, name });
    addChildrenList(category.children, list, mark, newCounter, maxLevel);
  });
};

export const createChildrenList = (
  categories: ICategoryItem[] | undefined,
  mark: string = '',
  maxLevel = 30
) => {
  const childrens: ICategoryParent[] = [];
  const counter = 0;
  addChildrenList(categories, childrens, mark, counter, maxLevel);
  return childrens;
};
