import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import { ILoyalty, ILoyaltyData } from '../../interfaces/loyalties';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import {
  createLoyaltyLevel,
  getLoyalties,
  getLoyaltyById,
  editLoyaltyLevel,
  deleteLoyalty,
} from '../../crud/loyalty.crud';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';

const FETCH_REQUEST = 'loyalty/FETCH_REQUEST';
const FETCH_SUCCESS = 'loyalty/FETCH_SUCCESS';
const FETCH_FAIL = 'loyalty/FETCH_FAIL';

const CLEAR_EDIT = 'loyalty/CLEAR_EDIT';
const EDIT_REQUEST = 'loyalty/EDIT_REQUEST';
const EDIT_SUCCESS = 'loyalty/EDIT_SUCCESS';
const EDIT_FAIL = 'loyalty/EDIT_FAIL';

const CREATE_REQUEST = 'loyalty/CREATE_REQUEST';
const LOYALTY_SUCCESS = 'loyalty/LOYALTY_SUCCESS';
const LOYALTY_FAIL = 'loyalty/LOYALTY_FAIL';

const FETCH_BY_ID_REQUEST = 'loyalty/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'loyalty/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'loyalty/FETCH_BY_ID_FAIL';

const DELETE_REQUEST = 'loyalty/DELETE_REQUEST';
const DELETE_SUCCESS = 'loyalty/DELETE_SUCCESS';
const DELETE_FAIL = 'loyalty/DELETE_FAIL';

export interface IInitialState {
  loyalties: ILoyalty[] | undefined;
  loyalty: ILoyalty | undefined;

  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  createLoading: boolean;
  createSuccess: boolean;
  createError: string | null;

  editStatusLoading: boolean;
  editStatusSuccess: boolean;
  editStatusError: string | null;

  loadingById: boolean;
  successById: boolean;
  errorById: string | null;

  delError: string | null;
  delLoading: boolean;
  delSuccess: boolean;
}

const initialState: IInitialState = {
  loyalties: undefined,
  loading: false,
  success: false,
  error: null,

  loyalty: undefined,

  editLoading: false,
  editSuccess: false,
  editError: null,

  createLoading: false,
  createSuccess: false,
  createError: null,

  editStatusLoading: false,
  editStatusSuccess: false,
  editStatusError: null,

  loadingById: false,
  successById: false,
  errorById: null,

  delError: null,
  delLoading: false,
  delSuccess: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'models', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          loyalties: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          loyalties: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          loyalty: undefined,
          editLoading: false,
          editSuccess: false,
          editError: null,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CREATE_REQUEST: {
        return { ...state, createLoading: true, createSuccess: false, createError: null };
      }

      case LOYALTY_SUCCESS: {
        return {
          ...state,
          createLoading: false,
          createSuccess: true,
          loyalties: state.loyalties ? [...state.loyalties, action.payload] : [action.payload],
        };
      }

      case LOYALTY_FAIL: {
        return { ...state, createLoading: false, createError: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          loadingById: true,
          successById: false,
          errorById: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          loyalty: action.payload.data,
          loadingById: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, loadingById: false, errorById: action.payload };
      }

      case DELETE_FAIL: {
        return { ...state, delError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<ILoyalty[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: { id: number; data: ILoyaltyData }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  addRequest: (payload: { data: ILoyaltyData }) => createAction(CREATE_REQUEST, payload),
  addSuccess: (payload: ILoyalty) => createAction(LOYALTY_SUCCESS, payload),
  addFail: (payload: string) => createAction(LOYALTY_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ILoyalty>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  delRequest: (payload: { id: number }) => createAction(DELETE_REQUEST, payload),
  delSuccess: () => createAction(DELETE_SUCCESS),
  delFail: (payload: string) => createAction(DELETE_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<ILoyalty[]> } = yield call(() => getLoyalties());
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* editSaga({
  payload: { id, data },
}: {
  payload: { id: number; data: ILoyaltyData };
}) {
  try {
    yield call(() => editLoyaltyLevel(id, data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { data: ILoyaltyData } }) {
  try {
    const { data }: { data: IServerResponse<ILoyalty> } = yield call(() =>
      createLoyaltyLevel(payload.data)
    );
    yield put(actions.addSuccess(data.data));
  } catch (e) {
    yield put(actions.addFail(getResponseMessage(e)));
  }
}

function* fetchSagaById({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ILoyalty> } = yield call(() =>
      getLoyaltyById(payload)
    );

    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* delSaga({
  payload,
}: {
  payload: {
    id: number;
  };
}) {
  try {
    yield call(() => deleteLoyalty(payload.id));
    yield put(actions.fetchRequest());
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchSagaById
  );
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(CREATE_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DELETE_REQUEST, delSaga);
}
