import React, { useCallback, useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IParticipation, ITender } from '../../../../interfaces/order';
import { actions as tendersActions } from '../../../../store/ducks/tenders.duck';
import { IAppState } from '../../../../store/rootDuck';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { useCreateParticipation } from '../hooks/useCreateParticipation';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import { useEditParticipation } from '../hooks/useEditParticipation';
import TableParticipations from './TableParticipations';
// import NumberFormatPercent from '../../../../components/NumberFormatPercent';
import NumberFormatPrice from '../../../../components/NumberFormatPrice';
import { useStylesModalAddParticipation } from '../hooks/useStyles';
import TextFieldCustom from '../../../../components/TextFieldCustom';

const getInitialValues = (participation?: IParticipation) => ({
  amount: participation?.amount || null,
  comment: participation?.comment || '',
  // rate: participation?.rate || null,
  // franchise: participation?.franchise || null,
});

interface IProps {
  tender?: ITender;
  formik: any;
}

const ParticipationTab: React.FC<IProps> = ({ tender, formik }) => {
  const dispatch = useDispatch();
  const fm = useFormatMessage();
  const classes1 = useStylesModalAddParticipation();
  const { classes } = classes1;
  const me = useSelector(({ profile }: IAppState) => profile.me, shallowEqual);
  const { participations, page, perPage } = useSelector(
    ({ tenders }: IAppState) => ({
      participations: tenders.participations,
      loading: tenders.loadingPart,
      page: tenders.pagePart,
      perPage: tenders.perPagePart,
    }),
    shallowEqual
  );
  const isVendor = useDefineUserRole(me, ['ROLE_VENDOR', 'ROLE_VENDOR_STAFF']);
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isMyTender = useMemo(() => me?.company?.id === tender?.client?.id, [me, tender]);
  const showTable = useMemo(() => {
    if (me?.is_admin || isManager || isMyTender) return true;
    if (isBuyer && tender?.public_proposals) return true;
    if (isVendor && tender?.public_proposals) return true;
    return false;
  }, [me, isManager, isVendor, isBuyer, tender, isMyTender]);

  const buyerParticipation = useMemo(
    () => participations?.find(item => item.company.id === me?.company?.id),
    [me, participations]
  );

  const getParticipations = useCallback(() => {
    tender &&
      me &&
      dispatch(
        tendersActions.fetchParticipation({
          tender_id: tender.id,
          page,
          per_page: perPage,
          company_id: isBuyer && !tender.public_proposals ? me.company?.id : undefined,
          statuses: isVendor ? 'new,accepted' : undefined,
        })
      );
  }, [tender, me, perPage, page, isBuyer, isVendor]);
  const disabled = useMemo(
    () => !!(isBuyer && buyerParticipation?.status === 'new'),
    [isBuyer, buyerParticipation]
  );
  const isEdit = useMemo(
    () => me?.is_admin || isManager || me?.company?.id === tender?.client?.id,
    [me, isManager, tender]
  );
  const [createParticipation, loadingCreate] = useCreateParticipation(getParticipations);
  const [editParticipation, loadingEdit] = useEditParticipation(getParticipations);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(isBuyer ? buyerParticipation : undefined),
    validationSchema:
      buyerParticipation?.status === 'new'
        ? Yup.object().shape({})
        : Yup.object().shape({
            amount: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
            // rate: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
            // franchise: Yup.number().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
            // comment: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
          }),
    onSubmit: submitValues => {
      if (isBuyer && tender && participations) {
        !buyerParticipation &&
          createParticipation(
            tender.id,
            submitValues,
            undefined,
            me?.company?.id === tender.client?.id
          );
        if (buyerParticipation) {
          buyerParticipation?.status === 'new'
            ? editParticipation(buyerParticipation.id, { status: 'canceled' })
            : editParticipation(
                buyerParticipation.id,
                { ...submitValues, status: 'new' },
                'new'
              );
        }
      }
    },
  });

  const changeStatus = useCallback((id: number, status: string) => {
    editParticipation(id, { status }, status === 'new' && 'new');
  }, []);

  const handleCreatePart = useCallback(
    (submitValues: any, company_id: number, isMain?: boolean) => {
      tender && createParticipation(tender.id, submitValues, company_id, isMain);
    },
    [tender]
  );

  useEffect(() => {
    getParticipations();
  }, [isBuyer, tender]);

  useEffect(() => {
    return () => {
      dispatch(tendersActions.clearPart());
    };
  }, []);

  // if (loading || (!participations && showTable)) {
  //   return <Preloader />;
  // }

  if (!me?.is_admin && !me?.company && !isManager)
    return (
      <>
        <Typography component='h5' variant='h5'>
          {fm('USER.NEED.COMPANY')}
        </Typography>
      </>
    );

  return (
    <>
      {isBuyer && me?.company?.id && (
        <>
          <TextFieldCustom
            type='text'
            label={fm('PARTICIPATION.FORM.SUM')}
            margin='normal'
            name='amount'
            value={values.amount}
            variant='outlined'
            helperText={touched.amount && errors.amount}
            error={Boolean(touched.amount && errors.amount)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.inputFocused,
                notchedOutline: classes.notchedOutline,
              },
              inputComponent: NumberFormatPrice as any,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelTextField,
                focused: classes.labelTextFieldFocused,
                shrink: classes.labelTextFieldShrink,
              },
            }}
          />
          {/* <TextFieldCustom
            type='text'
            label={fm('PARTICIPATION.FORM.TARIFF')}
            margin='normal'
            name='rate'
            value={values.rate}
            variant='outlined'
            helperText={touched.rate && errors.rate}
            error={Boolean(touched.rate && errors.rate)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.inputFocused,
                notchedOutline: classes.notchedOutline,
              },
              inputComponent: NumberFormatPercent as any,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelTextField,
                focused: classes.labelTextFieldFocused,
                shrink: classes.labelTextFieldShrink,
              },
            }}
          /> */}
          {/* <TextFieldCustom
            type='text'
            label={fm('PARTICIPATION.FORM.FRANCHISE')}
            margin='normal'
            name='franchise'
            value={values.franchise}
            variant='outlined'
            helperText={touched.franchise && errors.franchise}
            error={Boolean(touched.franchise && errors.franchise)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.inputFocused,
                notchedOutline: classes.notchedOutline,
              },
              inputComponent: NumberFormatPrice as any,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelTextField,
                focused: classes.labelTextFieldFocused,
                shrink: classes.labelTextFieldShrink,
              },
            }}
          /> */}
          <TextFieldCustom
            type='text'
            label={fm('PARTICIPATION.FORM.COMMENT')}
            margin='normal'
            name='comment'
            value={values.comment}
            variant='outlined'
            helperText={touched.comment && errors.comment}
            error={Boolean(touched.comment && errors.comment)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            multiline
            rows={10}
            InputProps={{
              classes: {
                root: classes.multilineInput,
                focused: classes.inputFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelTextField,
                focused: classes.labelTextFieldFocused,
                shrink: classes.labelTextFieldShrink,
              },
            }}
          />
          <ButtonWithLoader
            disabled={loadingCreate || loadingEdit}
            loading={loadingCreate || loadingEdit}
            onPress={handleSubmit}
          >
            {fm(
              buyerParticipation?.status === 'new'
                ? 'PARTICIPATION.FORM.REVOKE'
                : 'PARTICIPATION.FORM.POST'
            )}
          </ButtonWithLoader>
        </>
      )}
      <TableParticipations
        participations={participations}
        changeStatus={changeStatus}
        handleCreatePart={handleCreatePart}
        formik={formik}
        isEdit={isEdit}
        tender={tender}
        showTable={showTable}
      />
    </>
  );
};

export default React.memo(ParticipationTab);
