import React, { useCallback, useState } from 'react';
import { DialogProps, Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { actions as productActions } from '../../../../../store/ducks/product.duck';
import { useFormatMessage } from '../../../../../hooks';
import { Modal } from '../../../../../components/other/Modals';
import ProductsTable from '../ProductsTable';
import { IUser } from '../../../../../interfaces/user';
import { IAppState } from '../../../../../store/rootDuck';
import { IProduct } from '../../../../../interfaces/product';
import AddProductForm from '../AddProductForm';
import { useStylesSearchView } from '../../hooks/useStyles';

type TProps = {
  id: number | string;
  changeTab: () => void;
  onlyButton?: boolean;
  title?: string;
};

const SearchAddProduct: React.FC<TProps> = ({ id, changeTab, onlyButton, title }) => {
  const classes = useStylesSearchView();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [openModal, setOpenModal] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);

  const { me } = useSelector(
    (store: IAppState) => ({
      me: store.profile.me,
    }),
    shallowEqual
  );
  const { loading, products, page, perPage, total } = useSelector(
    (store: IAppState) => ({
      products: store.products.products,
      loading: store.products.loading,
      page: store.products.page,
      perPage: store.products.per_page,
      total: store.products.total,
    }),
    shallowEqual
  );

  const handleAddProduct = useCallback(() => {
    if (!me?.company) {
      enqueueSnackbar(`${fm('ERROR')}: ${fm('YOU_HAVE_NO_COMPANY')}`, {
        variant: 'error',
      });
    } else {
      changeTab();
      dispatch(productActions.fetchRequest({ page, perPage, companyId: me.company.id }));
      setOpenModal(true);
    }
  }, [me, page, perPage]);

  if (onlyButton) {
    return me?.company ? (
      <div className={classes.btnAdd} onClick={handleAddProduct}>
        <b className={classes.titleAdd}>{fm('SEARCH.ADD.PRODUCT').toUpperCase()}</b>
      </div>
    ) : (
      <></>
    );
  }

  return (
    <>
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={fm('SEARCH.PRODUCTS.TABLE.TITLE')}
        loading={loading}
        content={
          <ProductsTable
            me={me as IUser}
            page={page}
            perPage={perPage}
            total={total}
            onSuggest={product => {
              setProduct(product);
              setOpenModal(false);
            }}
            close={() => setOpenModal(false)}
            products={products || []}
            fetch={({ page, perPage }) =>
              me?.company &&
              dispatch(
                productActions.fetchRequest({ page, perPage, companyId: me.company.id })
              )
            }
          />
        }
      />
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={!!product}
        onClose={() => setProduct(null)}
        title={fm('SEARCH.MODAL.ADD.PRODUCT.TITLE')}
        loading={loading}
        content={
          <AddProductForm
            product={product}
            onCancel={() => {
              setProduct(null);
              setOpenModal(true);
            }}
            close={() => setProduct(null)}
            id={id}
          />
        }
      />
      <div style={{ marginTop: 25 }}>
        {title && <Typography className={classes.titleVendors}>{title}</Typography>}
        {me?.company && (
          <div className={classes.btnAdd} onClick={handleAddProduct}>
            <b className={classes.titleAdd}>{fm('SEARCH.ADD.PRODUCT').toUpperCase()}</b>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(SearchAddProduct);
