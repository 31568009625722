import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../../utils/utils';
import { useFormatMessage } from '../../../../../hooks';
import { createInvite } from '../../../../../crud/store.crud';

export const useCreateInvite = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingInvite, setLoading] = useState(false);
  const [successInvite, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchInvite = useCallback(
    async (data: {
      role: string;
      email: string;
      country_code: string;
      phone_numbers: string;
      login: string;
      stores_ids?: string;
      categories_ids?: string;
      company_id?: string;
    }) => {
      setLoading(true);
      createInvite(data)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(fm('INVITE.SUCCESS.CREATE'), { variant: 'success' });
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return { fetchInvite, loadingInvite, successInvite };
};
