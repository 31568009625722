import React, { Fragment, useCallback, useState } from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Close, Block } from '@mui/icons-material'; // Krestik iconka uchun import
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IWorkingTime } from '../../../../interfaces/store';
import { useFormatMessage } from '../../../../hooks';
import { ButtonWithLoader } from '../../../../components/other/Buttons';

interface IProps {
  dates: IWorkingTime[];
  loading: boolean;
  submit: any;
}

const useStyles = makeStyles()(theme => {
  return {
    card: {
      marginTop: theme.spacing(3),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    buttonsSetDays: {
      marginLeft: theme.spacing(2),
    },
    autoLoc: {
      width: '100%',
    },
    editor: {
      minHeight: 305,
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
      },
    },
    list: {
      [theme.breakpoints.down('sm')]: {
        width: '450px',
      },
    },
  };
});

const Shedule = ({ dates, loading, submit }: IProps) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;
  const navigate = useNavigate();
  const fm = useFormatMessage();

  const [disabledDates, setDisabledDates] = useState<Record<string, boolean>>({});

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      All_day_from: '00:00',
      All_day_to: '00:00',
      Monday_from:
        dates && Array.isArray(dates) && dates.length ? dates[0].start_time : '00:00',
      Monday_to: dates && Array.isArray(dates) && dates.length ? dates[0].stop_time : '00:00',

      Tuesday_from:
        dates && Array.isArray(dates) && dates.length ? dates[1].start_time : '00:00',
      Tuesday_to: dates && Array.isArray(dates) && dates.length ? dates[1].stop_time : '00:00',

      Wednesday_from:
        dates && Array.isArray(dates) && dates.length ? dates[2].start_time : '00:00',
      Wednesday_to:
        dates && Array.isArray(dates) && dates.length ? dates[2].stop_time : '00:00',

      Thursday_from:
        dates && Array.isArray(dates) && dates.length ? dates[3].start_time : '00:00',
      Thursday_to:
        dates && Array.isArray(dates) && dates.length ? dates[3].stop_time : '00:00',

      Friday_from:
        dates && Array.isArray(dates) && dates.length ? dates[4].start_time : '00:00',
      Friday_to: dates && Array.isArray(dates) && dates.length ? dates[4].stop_time : '00:00',

      Saturday_from:
        dates && Array.isArray(dates) && dates.length ? dates[5].start_time : '00:00',
      Saturday_to:
        dates && Array.isArray(dates) && dates.length ? dates[5].stop_time : '00:00',

      Sunday_from:
        dates && Array.isArray(dates) && dates.length ? dates[6].start_time : '00:00',
      Sunday_to: dates && Array.isArray(dates) && dates.length ? dates[6].stop_time : '00:00',
    },
    onSubmit: values => {
      submit({
        Monday_from: values.Monday_from || '00:00',
        Monday_to: values.Monday_to || '00:00',

        Tuesday_from: values.Tuesday_from || '00:00',
        Tuesday_to: values.Tuesday_to || '00:00',

        Wednesday_from: values.Wednesday_from || '00:00',
        Wednesday_to: values.Wednesday_to || '00:00',

        Thursday_from: values.Thursday_from || '00:00',
        Thursday_to: values.Thursday_to || '00:00',

        Friday_from: values.Friday_from || '00:00',
        Friday_to: values.Friday_to || '00:00',

        Saturday_from: values.Saturday_from || '00:00',
        Saturday_to: values.Saturday_to || '00:00',

        Sunday_from: values.Sunday_from || '00:00',
        Sunday_to: values.Sunday_to || '00:00',
      });
    },
    validationSchema: Yup.object().shape({}),
  });

  const list: { title: string; from_date: string; to_date: string }[] = [
    { title: fm('SHEDULE.ALL_DAYS'), from_date: 'All_day_from', to_date: 'All_day_to' },
    { title: fm('SHEDULE.Monday'), from_date: 'Monday_from', to_date: 'Monday_to' },
    { title: fm('SHEDULE.Tuesday'), from_date: 'Tuesday_from', to_date: 'Tuesday_to' },
    { title: fm('SHEDULE.Wednesday'), from_date: 'Wednesday_from', to_date: 'Wednesday_to' },
    { title: fm('SHEDULE.Thursday'), from_date: 'Thursday_from', to_date: 'Thursday_to' },
    { title: fm('SHEDULE.Friday'), from_date: 'Friday_from', to_date: 'Friday_to' },
    { title: fm('SHEDULE.Saturday'), from_date: 'Saturday_from', to_date: 'Saturday_to' },
    { title: fm('SHEDULE.Sunday'), from_date: 'Sunday_from', to_date: 'Sunday_to' },
  ];

  const handleSetDays = useCallback(() => {
    list.forEach(item => {
      if (item.title !== fm('SHEDULE.ALL_DAYS')) {
        setFieldValue(item.from_date, values.All_day_from);
        setFieldValue(item.to_date, values.All_day_to);
      }

      if (item.title === fm('SHEDULE.Saturday') || item.title === fm('SHEDULE.Sunday')) {
        setDisabledDates(prev => ({
          ...prev,
          [item.from_date]: false,
        }));
      }
    });
  }, [values.All_day_from, values.All_day_to, list, fm, setFieldValue]);

  const handleClearField = (field: string) => {
    setFieldValue(field, '00:00');
  };

  const toggleDisabled = (from: string, to: string) => {
    const isDisabled = !disabledDates[from];
    setDisabledDates(prev => ({ ...prev, [from]: isDisabled }));

    if (isDisabled) {
      setFieldValue(from, '00:00');
      setFieldValue(to, '00:00');
    }
  };

  return (
    <form
      style={{
        width: '100%',
        padding: '24px',
        maxWidth: '800px',
      }}
      onSubmit={handleSubmit}
    >
      <div className={classes.table}>
        <List dense component='div' role='list' className={classes.list}>
          {list.map((item, index, arr) => (
            <Fragment key={item.title}>
              <ListItem role='listitem' style={{ paddingBottom: 10, paddingTop: 10 }}>
                <ListItemText primary={item.title} />
                <TextField
                  label={intl.formatMessage({ id: 'SHEDULE.FROM' })}
                  type='time'
                  name={item.from_date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={(values as any)[item.from_date]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ padding: 0, margin: 0 }} position='end'>
                        <IconButton
                          style={{ padding: 0, margin: 0 }}
                          size='small'
                          onClick={() => handleClearField(item.from_date)}
                        >
                          <Close fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: 110,
                    marginRight: 1.3,
                    '& .MuiInputBase-input': {
                      height: '26px',
                      padding: '5px',
                    },
                  }}
                />

                <TextField
                  label={intl.formatMessage({ id: 'SHEDULE.TO' })}
                  type='time'
                  name={item.to_date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={(values as any)[item.to_date]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ padding: 0, margin: 0 }} position='end'>
                        <IconButton
                          style={{ padding: 0, margin: 0 }}
                          size='small'
                          onClick={() => handleClearField(item.to_date)}
                        >
                          <Close fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: 110,
                    marginRight: 1.3,
                    '& .MuiInputBase-input': {
                      height: '26px',
                      padding: '5px',
                    },
                  }}
                />

                {item.title !== fm('SHEDULE.ALL_DAYS') && (
                  <IconButton
                    size='small'
                    onClick={() => toggleDisabled(item.from_date, item.to_date)}
                  >
                    <Block
                      color={
                        disabledDates[item.from_date] ||
                        (values as any)[item.from_date] === '00:00'
                          ? 'error'
                          : 'primary'
                      }
                      fontSize='small'
                    />
                  </IconButton>
                )}

                {item.title === fm('SHEDULE.ALL_DAYS') && (
                  <Button
                    onClick={handleSetDays}
                    className={classes.buttonsSetDays}
                    variant='contained'
                    color='primary'
                  >
                    {fm('SHEDULE.COPY')}
                  </Button>
                )}
              </ListItem>

              {index !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}
        </List>

        <div className={classes.actions}>
          <Button
            onClick={() => navigate(-1)}
            className={classes.buttons}
            variant='outlined'
            color='primary'
          >
            {intl.formatMessage({ id: 'CATEGORY.BUTTON.CANCEL' })}
          </Button>

          <ButtonWithLoader disabled={loading} loading={loading}>
            {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
          </ButtonWithLoader>
        </div>
      </div>
    </form>
  );
};

export default Shedule;
