import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { addProductToOrder } from '../../../../crud/orders.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useAddProductToTender = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingAdd, setLoading] = useState(false);
  const [errAdd, setErr] = useState<any>(null);
  const [successAdd, setSuccess] = useState(false);
  const addRisk = useCallback(async (product_id: number, id: number) => {
    setLoading(true);
    addProductToOrder(product_id, id)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${intl.formatMessage({ id: 'RISK.SUCCESS.ADD' })}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setSuccess(false);
      });
  }, []);
  return { addRisk, loadingAdd, successAdd, errAdd };
};
