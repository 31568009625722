import React from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, TableFooter } from '@mui/material';
import { useIntl } from 'react-intl';

import { IUser } from '../../../../../interfaces/user';
import { IProduct } from '../../../../../interfaces/product';
import TablePaginator from '../../../../../components/TablePaginator/TablePaginator';
import { toAbsoluteUrl } from '../../../../../../_base';
import { getProductImage } from '../../../products/utils/getProductImage';
import { thousands } from '../../../../../utils/utils';
import { SHeadTableCell } from '../../../../../components/styledComponents/Tables';
import { useStylesProductsTable } from '../../hooks/useStyles';

interface IProps {
  products: IProduct[];
  page: number;
  perPage: number;
  total: number;
  onSuggest: (value: IProduct) => void;
  me: IUser;
  fetch?: (data: { page: number; perPage: number }) => void;
  close: () => void;
}

const ProductsTable: React.FC<IProps> = ({
  products,
  page,
  perPage,
  total,
  onSuggest,
  fetch,
  close,
}) => {
  const classes = useStylesProductsTable();
  const intl = useIntl();

  return (
    <div className={classes.table}>
      <Table aria-label='CompaniesTable'>
        <TableHead>
          <TableRow>
            <SHeadTableCell className={classes.cell}>
              {intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}
            </SHeadTableCell>
            <SHeadTableCell className={classes.cell}>
              {intl.formatMessage({ id: 'PUBLICATIONS.TABS.PHOTOS' })}
            </SHeadTableCell>
            <SHeadTableCell className={classes.cell}>
              {intl.formatMessage({ id: 'title' })}
            </SHeadTableCell>
            <SHeadTableCell className={classes.cell}>
              {intl.formatMessage({ id: 'PRODUCT.TABLE.PRICE' })}
            </SHeadTableCell>
            <SHeadTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <TableRow key={product.id?.toString()}>
              <TableCell className={classes.cell}>{product.id || '-'}</TableCell>
              <TableCell className={classes.cell}>
                <img
                  src={getProductImage(product)}
                  className={classes.img}
                  alt=' '
                  onError={(e: any) => {
                    e.target.src = toAbsoluteUrl('/images/placeholder.png');
                  }}
                />
              </TableCell>
              <TableCell className={classes.cell}>{product.name || '-'}</TableCell>
              <TableCell className={classes.cell}>
                {product.price ? thousands(String(product.price)) : '-'}
              </TableCell>
              <TableCell style={{ paddingRight: 0 }}>
                <div className={classes.btnAdd} onClick={() => onSuggest(product)}>
                  <b className={classes.titleAdd}>
                    {intl.formatMessage({ id: 'SEARCH.OFFER' }).toUpperCase()}
                  </b>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePaginator
              page={page}
              perPage={perPage}
              fetchRows={fetch}
              total={total}
              label={intl.formatMessage({ id: 'PRODUCT.TABLE.PERPAGE' })}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <div className={classes.btnCancel} onClick={close}>
        <b className={classes.titleCancel}>
          {intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }).toUpperCase()}
        </b>
      </div>
    </div>
  );
};

export default ProductsTable;
