import { ITariff } from '../../../../interfaces/tariff';
import { PlanI } from '../interfaces';

const getPlans = (tariff: ITariff): PlanI[] => {
  return [
    {
      name: tariff.name,
      price: tariff.price7days,
      days: 7,
      tariff_id: tariff.id,
    },
    {
      name: tariff.name,
      price: tariff.price30days,
      days: 30,
      tariff_id: tariff.id,
    },
    {
      name: tariff.name,
      price: tariff.price120days,
      days: 120,
      tariff_id: tariff.id,
    },
    {
      name: tariff.name,
      price: tariff.price365days,
      days: 365,
      tariff_id: tariff.id,
    },
  ];
};

export default getPlans;
