import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';

import {
  IBanner,
  ICreateBanner,
  IEditBanner,
  Placement,
  PlacementType,
} from '../../pages/home/banners/interfaces';
import {
  createBanner,
  deleteBanner,
  editBanner,
  getBanner,
  getBanners,
} from '../../crud/banners.crud';
import { getResponseMessage } from '../../utils/utils';

const FETCH_BANNERS_REQUEST = 'banners/FETCH_BANNERS_REQUEST';
const FETCH_BANNERS_SUCCESS = 'banners/FETCH_BANNERS_SUCCESS';
const FETCH_BANNERS_FAIL = 'banners/FETCH_BANNERS_FAIL';

const CLEAR_CREATE_BANNER = 'banners/CLEAR_CREATE_BANNER';
const CREATE_BANNER_REQUEST = 'banners/CREATE_BANNER_REQUEST';
const CREATE_BANNER_SUCCESS = 'banners/CREATE_BANNER_SUCCESS';
const CREATE_BANNER_FAIL = 'banners/CREATE_BANNER_FAIL';

const CLEAR_EDIT_BANNER = 'banners/CLEAR_EDIT_BANNER';
const EDIT_BANNER_REQUEST = 'banners/EDIT_BANNER_REQUEST';
const EDIT_BANNER_SUCCESS = 'banners/EDIT_BANNER_SUCCESS';
const EDIT_BANNER_FAIL = 'banners/EDIT_BANNER_FAIL';

const CLEAR_DELETE_BANNER = 'banners/CLEAR_DELETE_BANNER';
const DELETE_BANNER_REQUEST = 'banners/DELETE_BANNER_REQUEST';
const DELETE_BANNER_SUCCESS = 'banners/DELETE_BANNER_SUCCESS';
const DELETE_BANNER_FAIL = 'banners/DELETE_BANNER_FAIL';

const CLEAR_FETCH_BANNER = 'banners/CLEAR_FETCH_BANNER';
const FETCH_BANNER_REQUEST = 'banners/FETCH_BANNER_REQUEST';
const FETCH_BANNER_SUCCESS = 'banners/FETCH_BANNER_SUCCESS';
const FETCH_BANNER_FAIL = 'banners/FETCH_BANNER_FAIL';

const FETCH_BANNERS_MAP_SUCCESS = 'banners/FETCH_BANNERS_MAP_SUCCESS';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;

  banners: IBanner[];
  banner: IBanner | null;
  bannersMap: Map<PlacementType, IBanner[]>;

  bannersLoading: boolean;
  bannersSuccess: boolean;
  bannersError: string | null;

  createBannerLoading: boolean;
  createBannerSuccess: boolean;
  createBannerError: string | null;

  editBannerLoading: boolean;
  editBannerSuccess: boolean;
  editBannerError: string | null;

  deleteBannerLoading: boolean;
  deleteBannerSuccess: boolean;
  deleteBannerError: string | null;

  fetchBannerLoading: boolean;
  fetchBannerSuccess: boolean;
  fetchBannerError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,

  banners: [],
  banner: null,
  bannersMap: new Map<PlacementType, IBanner[]>(),

  bannersLoading: false,
  bannersSuccess: false,
  bannersError: null,

  createBannerLoading: false,
  createBannerSuccess: false,
  createBannerError: null,

  editBannerLoading: false,
  editBannerSuccess: false,
  editBannerError: null,

  deleteBannerLoading: false,
  deleteBannerSuccess: false,
  deleteBannerError: null,

  fetchBannerLoading: false,
  fetchBannerSuccess: false,
  fetchBannerError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'banners', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      // GET ALL BANNERS

      case FETCH_BANNERS_REQUEST: {
        return {
          ...state,
          bannersLoading: true,
          bannersSuccess: false,
          Error: null,
        };
      }

      case FETCH_BANNERS_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,

          bannersLoading: false,
          bannersSuccess: true,
          banners: action.payload.data,
        };
      }

      case FETCH_BANNERS_FAIL: {
        return { ...state, bannersLoading: false, bannersError: action.payload };
      }

      // CREATE BANNER

      case CLEAR_CREATE_BANNER: {
        return {
          ...state,
          createBannerLoading: false,
          createBannerSuccess: false,
          createBannerError: null,
        };
      }

      case FETCH_BANNERS_MAP_SUCCESS: {
        return {
          ...state,
          bannersMap: action.payload,
        };
      }

      case CREATE_BANNER_REQUEST: {
        return {
          ...state,
          createBannerLoading: true,
          createBannerSuccess: false,
          createBannerError: null,
        };
      }

      case CREATE_BANNER_SUCCESS: {
        return {
          ...state,
          createBannerLoading: false,
          createBannerSuccess: true,
        };
      }

      case CREATE_BANNER_FAIL: {
        return {
          ...state,
          createBannerLoading: false,
          createBannerError: action.payload,
        };
      }

      // EDIT BANNER
      case CLEAR_EDIT_BANNER: {
        return {
          ...state,
          editBannerLoading: false,
          editBannerSuccess: false,
          editBannerError: null,
        };
      }

      case EDIT_BANNER_REQUEST: {
        return {
          ...state,
          editBannerLoading: true,
          editBannerSuccess: false,
          editBannerError: null,
        };
      }

      case EDIT_BANNER_SUCCESS: {
        return {
          ...state,
          banner: action.payload.data,
          editBannerLoading: false,
          editBannerSuccess: true,
        };
      }

      case EDIT_BANNER_FAIL: {
        return {
          ...state,
          editBannerLoading: false,
          editBannerError: action.payload,
        };
      }

      // DELETE BANNER
      case CLEAR_DELETE_BANNER: {
        return {
          ...state,
          deleteBannerLoading: false,
          deleteBannerSuccess: false,
          deleteBannerError: null,
        };
      }

      case DELETE_BANNER_REQUEST: {
        return {
          ...state,
          deleteBannerLoading: true,
          deleteBannerSuccess: false,
        };
      }

      case DELETE_BANNER_SUCCESS: {
        return {
          ...state,
          deleteBannerLoading: false,
          deleteBannerSuccess: true,
        };
      }

      case DELETE_BANNER_FAIL: {
        return {
          ...state,
          deleteBannerLoading: false,
          deleteBannerError: action.payload,
        };
      }

      // FETCH BANNER
      case CLEAR_FETCH_BANNER: {
        return {
          ...state,
          banner: null,
          fetchBannerLoading: false,
          fetchBannerSuccess: false,
          fetchBannerError: null,
        };
      }

      case FETCH_BANNER_REQUEST: {
        return {
          ...state,
          fetchBannerLoading: true,
          fetchBannerSuccess: false,
        };
      }

      case FETCH_BANNER_SUCCESS: {
        return {
          ...state,
          banner: action.payload.data,
          fetchBannerLoading: false,
          fetchBannerSuccess: true,
        };
      }

      case FETCH_BANNER_FAIL: {
        return {
          ...state,
          fetchBannerLoading: false,
          fetchBannerError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  bannersRequest: (payload: { page: number; perPage: number }) =>
    createAction(FETCH_BANNERS_REQUEST, payload),
  bannersSuccess: (payload: IServerResponse<IBanner[]>) =>
    createAction(FETCH_BANNERS_SUCCESS, payload),
  bannersFail: (payload: string) => createAction(FETCH_BANNERS_FAIL, payload),

  clearCreateBanner: () => createAction(CLEAR_CREATE_BANNER),
  createBannerRequest: (payload: { data: any }) =>
    createAction(CREATE_BANNER_REQUEST, payload),
  createBannerSuccess: (payload: IServerResponse<IBanner>) =>
    createAction(CREATE_BANNER_SUCCESS, payload),
  createBannerFail: (payload: string) => createAction(CREATE_BANNER_FAIL, payload),

  setBannersMap: (payload: Map<PlacementType, IBanner[]>) =>
    createAction(FETCH_BANNERS_MAP_SUCCESS, payload),

  clearEdiBanner: () => createAction(CLEAR_EDIT_BANNER),
  editBannerRequest: (payload: { id: number; data: Partial<IEditBanner> }) =>
    createAction(EDIT_BANNER_REQUEST, payload),
  editBannerSuccess: (payload: IServerResponse<IBanner>) =>
    createAction(EDIT_BANNER_SUCCESS, payload),
  editBannerFail: (payload: string) => createAction(EDIT_BANNER_FAIL, payload),

  clearDeleteBanner: () => createAction(CLEAR_DELETE_BANNER),
  deleteBannerRequest: (payload: { id: number }) =>
    createAction(DELETE_BANNER_REQUEST, payload),
  deleteBannerSuccess: () => createAction(DELETE_BANNER_SUCCESS),
  deleteBannerFail: (payload: string) => createAction(DELETE_BANNER_FAIL, payload),

  clearFetchBanner: () => createAction(CLEAR_FETCH_BANNER),
  fetchBannerRequest: (payload: { id: number }) => createAction(FETCH_BANNER_REQUEST, payload),
  fetchBannerSuccess: (payload: IServerResponse<IBanner>) =>
    createAction(FETCH_BANNER_SUCCESS, payload),
  fetchBannerFail: (payload: string) => createAction(FETCH_BANNER_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchBannersSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
  };
}) {
  try {
    const bannerMap = new Map<PlacementType, IBanner[]>([
      [Placement.TOP, []],
      [Placement.MID, []],
      [Placement.BOTTOM, []],
    ]);
    const { data }: { data: IServerResponse<IBanner[]> } = yield call(() =>
      getBanners(payload)
    );
    data.data.forEach(banner => {
      const banners = bannerMap.get(banner.placement) || [];
      bannerMap.set(banner.placement, [banner, ...banners]);
    });
    yield put(actions.bannersSuccess(data));
    yield put(actions.setBannersMap(bannerMap));
  } catch (e) {
    yield put(actions.bannersFail(getResponseMessage(e)));
  }
}

function* createBannerSaga({ payload }: { payload: { data: ICreateBanner } }) {
  try {
    const { data }: { data: IServerResponse<IBanner> } = yield call(() =>
      createBanner(payload.data)
    );
    yield put(actions.createBannerSuccess(data));
  } catch (e) {
    yield put(actions.createBannerFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deleteBannerSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => deleteBanner(payload.id));
    yield put(actions.deleteBannerSuccess());
  } catch (e) {
    yield put(actions.deleteBannerFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editBannerSaga({ payload }: { payload: { id: number; data: IEditBanner } }) {
  try {
    const { data }: { data: IServerResponse<IBanner> } = yield call(() =>
      editBanner(payload.id, payload.data)
    );
    yield put(actions.editBannerSuccess(data));
  } catch (e) {
    yield put(actions.editBannerFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchBannerSaga({ payload }: { payload: { id: number } }) {
  try {
    const { data }: { data: IServerResponse<IBanner> } = yield call(() =>
      getBanner(payload.id)
    );
    yield put(actions.fetchBannerSuccess(data));
  } catch (e) {
    yield put(actions.fetchBannerFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.bannersRequest>>(
    FETCH_BANNERS_REQUEST,
    fetchBannersSaga
  );
  yield takeLatest<ReturnType<typeof actions.createBannerRequest>>(
    CREATE_BANNER_REQUEST,
    createBannerSaga
  );
  yield takeLatest<ReturnType<typeof actions.editBannerRequest>>(
    EDIT_BANNER_REQUEST,
    editBannerSaga
  );
  yield takeLatest<ReturnType<typeof actions.deleteBannerRequest>>(
    DELETE_BANNER_REQUEST,
    deleteBannerSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchBannerRequest>>(
    FETCH_BANNER_REQUEST,
    fetchBannerSaga
  );
}
