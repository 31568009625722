import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, IconButton, MenuItem, TextField } from '@mui/material';

import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import { CloseOutlined } from '@mui/icons-material';
import Preloader from '../../../components/other/Preloader/Preloader';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import CategoriesTable from './components/CategoriesTable';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { categoriesBreadCrumbs } from './utils/createBreadcrumbs';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { getLangList, setLangList } from './utils/langList';
import { categoryLanguages } from './utils/categoryLanguages';
import { useStylesCategoriesList } from './hooks/useStyles';
import { ICategoryItem } from './interfaces';
import homeStyles from '../homeStyles';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';

const CategoriesListPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  total,

  fetch,
  clearFetch,
  categories,
  categoriesLoading,

  fetchParent,
  clearParent,
  parent,
  parentLoading,

  deleteCategory,
  clearDelete,
  deleteLoading,
  deleteSuccess,
  deleteError,

  fetchMe,
  clearMe,
  me,
  loadingMe,

  setPositions,
}) => {
  const intl = useIntl();
  const classes1 = useStylesCategoriesList();
  const { classes } = classes1;
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const { parentId } = useParams();
  const fm = useFormatMessage();

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [lang, setLang] = useState('ru');
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        text: '',
      },
      // validationSchema: Yup.object().shape({
      //   text: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).nullable(),
      // }),
      onSubmit: submitValues => {
        fetch({
          page: 1,
          perPage: 20,
          parentId: parentId ? +parentId : undefined,
          text: submitValues.text.trim().toLocaleLowerCase() || undefined,
        });
      },
    });

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const deleteAction = useCallback(() => {
    setAlertOpen(false);
    deleteCategory({ id: deleteId });
  }, [deleteId, deleteCategory]);

  const editAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/categories/edit/${item.id}/${parentId || ''}`);
    },
    [parentId, navigate]
  );

  const viewAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/categories/view/${item.id}`);
    },
    [navigate]
  );

  const productListAction = useCallback(
    (item: ICategoryItem) => {
      navigate(`/products/list/${item.id}`);
    },
    [navigate]
  );

  const categoryProductListAction = useCallback(() => {
    navigate(`/products/list/${parentId}`);
  }, [parentId, navigate]);

  const pushAction = useCallback(
    (parentIdProp?: string) => {
      navigate(`${parentIdProp ? `/categories/new/${parentIdProp}` : '/categories/new'}`);
    },
    [navigate]
  );

  const handleChangeLang = useCallback((value: string) => {
    setLang(value);
    setLangList(value);
  }, []);

  const productAction = useCallback(() => {
    const path = parentId ? `/products/create/${parentId}` : '/products/create';
    navigate(path);
  }, [parentId, navigate]);

  setLayoutSubheader({
    title: parent?.name || intl.formatMessage({ id: 'MENU.CATALOG.CATEGORIES.LIST' }),
    breadcrumb: parent?.name ? categoriesBreadCrumbs(parent?.parent, intl) : [],
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    fetchMe();
    const lang = getLangList();
    setLang(intl.locale || lang || 'ru');
    return () => {
      clearMe();
      clearParent();
      clearDelete();
      clearFetch();
    };
  }, []);

  useEffect(() => {
    clearFetch();
  }, [parentId]);

  useEffect(() => {
    fetch({ page: 1, perPage: 20, parentId: parentId ? +parentId : undefined });
  }, [fetch, parentId, fetchParent]);

  useEffect(() => {
    if (parentId) {
      fetchParent({ parentId: Number.parseInt(parentId, 10) });
    } else {
      clearParent();
    }
  }, [parentId, fetchParent]);

  useCrudSnackbar({
    success: deleteSuccess,
    error: deleteError,
    clear: clearDelete,
    successMessage: intl.formatMessage({ id: 'CATEGORY.SNACKBAR.DELETE' }),
    errorMessage: `${intl.formatMessage({ id: 'CATEGORIES.DELETE.ERROR' })}`,
    afterSuccess: () => {
      fetch({ page, perPage, parentId: parentId ? +parentId : undefined });
    },
    afterSuccessOrError: () => {
      clearDelete();
    },
  });

  const showAddProductButton = !!parentId;

  if (categoriesLoading || parentLoading || loadingMe || deleteLoading || !categories) {
    return <Preloader />;
  }

  return (
    <>
      <div className={classes.actions}>
        <div className={classes.containerBtns} style={{ marginRight: 20 }}>
          {me?.is_admin && (
            <div style={{marginRight: 15}}>
              <Button color='primary' variant='contained' onClick={() => pushAction(parentId)}>
                {intl.formatMessage({ id: 'CATEGORIES.BUTTON.ADD' })}
              </Button>
            </div>
          )}
          {showAddProductButton && (
            <div style={{marginRight: 15}}>
              <Button color='primary' variant='contained' onClick={productAction}>
                {intl.formatMessage({ id: 'CATEGORY.BUTTON.ADD.PRODUCT' })}
              </Button>
            </div>
          )}
          {parentId && (
            <div>
              <Button color='primary' variant='outlined' onClick={categoryProductListAction}>
                {intl.formatMessage({ id: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS' })}
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <form
            className={homeClasses.classes.formSearch}
            onSubmit={handleSubmit}
            autoComplete='off'
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                type='text'
                label={fm('CATEGORY.REQUIRED.NAME')}
                margin='normal'
                name='text'
                value={values.text}
                className={classes.search}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.text && errors.text}
                error={Boolean(touched.text && errors.text)}
                InputProps={{
                  endAdornment: values.text && (
                    <IconButton
                      onClick={() => {
                        setFieldValue('text', '');
                        handleSubmit();
                      }}
                    >
                      <CloseOutlined fontSize='small' color='disabled' />
                    </IconButton>
                  ),
                }}
              />
              <div className={classes.wrapperFind}>
                <ButtonWithLoader
                  loading={categoriesLoading}
                  disabled={categoriesLoading}
                  fullHeight
                >
                  {fm('PRODUCT.FILTER.SEARCH')}
                </ButtonWithLoader>
              </div>
            </div>
          </form>
        </div>
        <TextField
          select
          value={lang || 'ru'}
          onChange={e => handleChangeLang(e.target.value)}
          name='locale'
          variant='standard'
          className={classes.selectLang}
          InputProps={{
            disableUnderline: true,
          }}
          SelectProps={{
            classes: {
              icon: classes.icon,
              select: classes.select,
            },
          }}
        >
          {categoryLanguages.map(lang => (
            <MenuItem key={lang.code} value={lang.code}>
              <div className={classes.lang}>
                <span className={classes.langImage}>
                  <img src={lang.flag} alt={lang.name} />
                </span>
                <span className={classes.langTitle}>{lang.name}</span>
              </div>
            </MenuItem>
          ))}
        </TextField>
      </div>

      <CategoriesTable
        page={page}
        perPage={perPage}
        total={total}
        categories={categories || []}
        handleDeleteDialog={handleDeleteDialog}
        editAction={editAction}
        parentId={parentId || ''}
        pushAction={pushAction}
        classes={classes}
        fetch={fetch}
        productListAction={productListAction}
        setPositions={setPositions}
        is_admin={me?.is_admin || false}
        isManager={isManager || false}
        lang={lang}
        viewAction={viewAction}
      />

      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'CATEGORIES.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteAction()}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.categories.page,
    perPage: state.categories.per_page,
    total: state.categories.total,

    categories: state.categories.categories,
    categoriesLoading: state.categories.categoriesLoading,

    parent: state.categories.parent,
    parentLoading: state.categories.parentLoading,

    deleteLoading: state.categories.deleteLoading,
    deleteSuccess: state.categories.deleteSuccess,
    deleteError: state.categories.deleteError,

    me: state.profile.me,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: categoriesActions.fetchRootRequest,
    clearFetch: categoriesActions.clearRootRequest,

    fetchParent: categoriesActions.fetchParentRequest,
    clearParent: categoriesActions.clearParentRequest,

    deleteCategory: categoriesActions.deleteRequest,
    clearDelete: categoriesActions.clearDelete,

    setPositions: categoriesActions.setPositionsRequest,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoriesListPage);
