import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Button, Card, IconButton, InputAdornment, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogProps } from '@mui/material/Dialog';

import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { useForbidAccess, useFormatMessage } from '../../../../hooks';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import homeStyles from '../../homeStyles';
import { getRole } from './utils';
import { UserListType } from '../constants';
import { useStylesEditPage } from './hooks/useStyleEditPage';
import SelectField from '../../../../components/ui/SelectField';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { useAxiosGetCallback } from '../../../../hooks/useAxiosGet';
import { useHandleCountrySelect } from '../../../auth/hooks/useHandleCountrySelect';
import { IStore } from '../../../../interfaces/store';
import { Modal } from '../../../../components/other/Modals';
import StoresTable from '../../../../components/tableComponents/Table/StoresTable';
import MultiSelect from '../../../../components/ui/MultiSelect';
import { createChildrenList } from '../../../../utils/createParentList';
import CompaniesTable from '../../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../../interfaces/user';
import CompanyBind from '../../companies/CompanyBind';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import { useGetStores } from './hooks/useGetStores';
import { useCreateInvite } from './hooks/useCreateInvite';
import { useFormikUserInvite } from './hooks/useFormikUserInvite';
import { IMaskPhoneInput } from '../../../auth/components/CheckInSystem';
import { needBindCompany } from '../../../../utils/user';

const UserInvite = () => {
  const { type } = useParams();
  const role = getRole(type as UserListType);
  const {state} = useLocation();
  const navigate = useNavigate();
  const roleIsManager = role === 'ROLE_MANAGER';
  const intl = useIntl();
  const homeClasses = homeStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { me, loadingMe } = useSelector(
    ({ profile: { me, loading } }: IAppState) => ({ me, loadingMe: loading }),
    shallowEqual
  );

  const { categories } = useSelector(
    ({ categories: { categories } }: IAppState) => ({ categories }),
    shallowEqual
  );
  const { companies, loadingCompanies, pageCompanies, perPageCompanies, totalCompanies } =
    useSelector(
      ({ companies: { companies, loading, page, per_page, total } }: IAppState) => ({
        companies,
        loadingCompanies: loading,
        pageCompanies: page,
        perPageCompanies: per_page,
        totalCompanies: total,
      }),
      shallowEqual
    );
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [openUserStoresDialog, setOpenUserStoresDialog] = useState(false);
  const fm = useFormatMessage();
  const classes = useStylesEditPage();

  const { data: countries, makeRequest } = useAxiosGetCallback<any>();

  const countrySelect = useHandleCountrySelect(countries, 76);
  const { setCountryId, countryData, countryId } = countrySelect;

  const childCategories = useMemo(() => {
    return createChildrenList(categories, '\u00B7 ');
  }, [categories, me]);

  const {
    fetchStores: fetchStoresModal,
    loading: loadingStoresModal,
    page: pageStoresModal,
    perPage: perPageStoresModal,
    total: totalStoresModal,
    stores: storesModal,
  } = useGetStores();

  const { fetchInvite, loadingInvite, successInvite } = useCreateInvite();

  const formik = useFormikUserInvite(role, fetchInvite, countryData, me, state?.company);

  const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  const openUserStoresModal = useCallback(() => {
    if (roleIsManager) {
      fetchStoresModal(1, 20, undefined);
      setOpenUserStoresDialog(true);
    } else if (values?.company) {
      fetchStoresModal(1, 20, values.company.id);
      setOpenUserStoresDialog(true);
    }
    if (me?.is_admin && values?.company) {
      enqueueSnackbar(fm('REQUIRED.COMPANY'), { variant: 'error' });
    }
  }, [pageStoresModal, perPageStoresModal, values, roleIsManager, me]);

  const openCompaniesDialog = useCallback(() => {
    dispatch(
      companiesActions.fetchRequest({
        perPage: 20,
        page: 1,
      })
    );
    setOpenDialogCompanies(true);
  }, [values]);

  useLayoutEffect(() => {
    makeRequest('/api/phone_codes');
    dispatch(categoriesActions.fetchFullRequest());
  }, []);

  useLayoutEffect(() => {
    successInvite && navigate(-1);
  }, [successInvite]);

  useForbidAccess(me, ['ROLE_MANAGER']);

  setLayoutSubheader({
    title: fm('INVITE'),
  });

  setLayoutFooter({ show: true });

  if (me && !me.is_admin && needBindCompany(me)) return <CompanyBind />;

  if (loadingMe) return <Preloader />;

  return (
    <>
      <Card className={homeClasses.classes.container}>
        <div className={homeClasses.classes.form}>
          {me?.is_admin && (
            <div className={classes.companyField}>
              <TextField
                label={fm('COMPANY.FORM.COMPANY')}
                margin='normal'
                placeholder={fm('COMPANY.FORM.COMPANY')}
                value={values?.company?.name || values?.company?.brand || values?.company?.id ||  ''}
                variant='outlined'
                error={Boolean(errors.company)}
                InputProps={{
                  readOnly: true,
                  endAdornment: values?.company && (
                    <IconButton
                      onClick={() => {
                        setFieldValue('company', null);
                      }}
                    >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  ),
                }}
              />
              <Button
                className={classes.allCompaniesBtn}
                style={{
                  marginTop: 8,
                  marginLeft: 10,
                  height: 52,
                }}
                variant='outlined'
                color='primary'
                onClick={openCompaniesDialog}
              >
                {fm('COMPANY.BUTTON.ALL')}
              </Button>
            </div>
          )}
          <TextField
            type='text'
            label={fm('USER.EDIT.EMAIL')}
            margin='normal'
            name='email'
            value={values.email}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.email}
            error={Boolean(errors.email)}
          />

          <div className={classes.dates}>
            <SelectField
              label={fm('USER.INPUT.COUNTRY_CODE')}
              data={countries}
              selectedValue={countryId}
              setSelectedValue={setCountryId}
              variant='outlined'
              renderItemText={item => (intl.locale === 'ru' ? item.ru_name : item.en_name)}
            />
            {countryData && (
              <IMaskPhoneInput
                mask={countryData.mask}
                onBlur={handleBlur}
                style={{
                  marginLeft: 10,
                }}
                value={values.phoneNumbers}
                onChange={(e: any) => {
                  setFieldValue(
                    'phoneNumbers',
                    e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                  );
                }}
                type='tel'
                label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                margin='normal'
                className='kt-width-full'
                name='phoneNumbers'
                variant='outlined'
                helperText={errors.phoneNumbers}
                error={Boolean(errors.phoneNumbers)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'> +{countryData.code} </InputAdornment>
                  ),
                }}
              />
            )}
          </div>
          {roleIsManager && (
            <MultiSelect
              label={fm('SPECIALTY')}
              options={childCategories.map(item => ({
                value: item.id?.toString() || '0',
                label: item.name,
              }))}
              selectedValues={values.categories}
              setSelectedValues={values => {
                setFieldValue('categories', values);
              }}
              error={Boolean(errors.categories)}
            />
          )}
          {/* {me?.is_admin && ( */}
          {/*  <div className={classes.companyField}> */}
          {/*    <TextField */}
          {/*      label={fm('SHIFT.STORE')} */}
          {/*      margin='normal' */}
          {/*      placeholder='' */}
          {/*      value={values.stores.map((i: IStore) => i.name).toString()} */}
          {/*      variant='outlined' */}
          {/*      error={Boolean(errors.stores)} */}
          {/*      InputProps={{ */}
          {/*        readOnly: true, */}
          {/*      }} */}
          {/*    /> */}
          {/*    <Button */}
          {/*      variant='outlined' */}
          {/*      color='primary' */}
          {/*      onClick={() => openUserStoresModal()} */}
          {/*      className={classes.allCompaniesBtn} */}
          {/*      style={{ */}
          {/*        marginTop: 8, */}
          {/*        marginLeft: 10, */}
          {/*        height: 52, */}
          {/*      }} */}
          {/*    > */}
          {/*      {fm('SUBMENU.LOCATIOINS')} */}
          {/*    </Button> */}
          {/*  </div> */}
          {/* )} */}
          <div className={classes.actions}>
            <Button
              onClick={() => navigate(-1)}
              className={classes.buttons}
              variant='outlined'
              color='primary'
            >
              {fm('COMMON.BUTTON.BACK')}
            </Button>
            <ButtonWithLoader
              onPress={handleSubmit}
              marginLeft={10}
              loading={loadingInvite}
              disabled={loadingInvite}
            >
              {fm('COMMON.BUTTON.CREATE')}
            </ButtonWithLoader>
          </div>
        </div>
      </Card>

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openUserStoresDialog}
        onClose={() => setOpenUserStoresDialog(false)}
        title={intl.formatMessage({ id: 'SUBMENU.LOCATIOINS' })}
        loading={loadingStoresModal}
        content={
          <StoresTable
            stores={storesModal}
            loading={loadingStoresModal}
            paginationData={{
              page: pageStoresModal,
              perPage: perPageStoresModal,
              total: totalStoresModal,
            }}
            connectedStores={values.stores.map(i => i.id)}
            onConnect={(_id, _name, _company, item) => {
              setFieldValue('stores', [item]);
            }}
            onConnectOff={id => {
              setFieldValue(
                'stores',
                values.stores.filter(i => i.id !== id)
              );
            }}
            fetch={({ page, perPage }) => {
              if (roleIsManager) {
                fetchStoresModal(page, perPage, undefined);
              } else if (values.company) {
                fetchStoresModal(page, perPage, values.company.id);
              }
            }}
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenUserStoresDialog(false),
          },
        ]}
      />

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openDialogCompanies}
        onClose={() => setOpenDialogCompanies(false)}
        title={fm('COMPANY.EDIT.MOUNT_TITLE')}
        loading={loadingCompanies}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            companySelect={values?.company}
            onConnectUser={company => {
              setFieldValue('company', company);
            }}
            companies={companies}
          />
        }
        actions={[
          { title: fm('COMMON.BUTTON.CLOSE'), onClick: () => setOpenDialogCompanies(false) },
        ]}
      />
    </>
  );
};

export default UserInvite;
