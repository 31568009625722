import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { IFile } from '../TenderPage';
import { API_DOMAIN } from '../../../../constants';
import { useStyles } from '../hooks/useStyles';
import { useAttachmentsTypes } from '../hooks/useAttachmentsTypes';
import { toAbsoluteUrl } from '../../../../../_base';
import DropZoneBtn from './DropZoneBtn';
import { useFormatMessage } from '../../../../hooks';

interface IProps {
  files: IFile[];
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
  isEditFiles: boolean;
}

const UploadFilesForm: React.FC<IProps> = ({ files, setFiles, isEditFiles }) => {
  const { data: attachmentTypes } = useAttachmentsTypes(true);
  const fileTypes = useMemo(
    () => attachmentTypes && Object.entries(attachmentTypes),
    [attachmentTypes]
  );
  const [currentType, setCurrentType] = useState('');
  const fm = useFormatMessage();
  const classes1 = useStyles();
  const { classes } = classes1;
  const { enqueueSnackbar } = useSnackbar();

  const uploadFiles = useCallback(
    (newFiles: File[], value: string) => {
      setFiles(s => [
        ...newFiles
          .filter(el => !files.find(y => y.file.name === el.name))
          .map(f => ({
            file: f,
            type: value,
            uploaded: false,
            id: null,
          })),
        ...s,
      ]);
    },
    [currentType]
  );

  return (
    <>
      <Typography component='h6' variant='h6' className={classes.titleFile}>
        {fm('TENDER.FILES.TITLE')}
      </Typography>
      {fileTypes &&
        fileTypes.map((item: any, index: any) => {
          const fileIsAvailable = files.find(file => file.type === item[0]);
          return (
            <div key={item[0]} className={classes.wrapperFile}>
              <div className={classes.wrapperBlocks} style={{ marginRight: '2%' }}>
                <div className={classes.wrapperBlock}>
                  {fileIsAvailable && fileIsAvailable.uploaded ? (
                    <img alt='check' src={toAbsoluteUrl(`/media/logos/checkTab.svg`)} />
                  ) : (
                    <b className={classes.indexFile}>{index + 1}</b>
                  )}
                </div>
                <div className={classes.fileInfo}>
                  <b className={classes.fileType}>{item[1]}</b>
                  {fileIsAvailable && (
                    <>
                      {fileIsAvailable.uploaded && fileIsAvailable.path ? (
                        <a
                          href={`${API_DOMAIN}${fileIsAvailable.path}`}
                          download
                          target='_blank'
                          rel='noreferrer'
                          className={classes.fileName}
                        >
                          {fileIsAvailable.file.name}
                        </a>
                      ) : (
                        <b className={classes.fileName}>{fileIsAvailable.file.name}</b>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes.wrapperBlocks}>
                {fileIsAvailable?.uploaded && fileIsAvailable?.path && (
                  <a
                    href={`${API_DOMAIN}${fileIsAvailable.path}`}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className={classes.fileBtn}>
                      <img alt='check' src={toAbsoluteUrl(`/media/logos/download.svg`)} />
                    </div>
                  </a>
                )}
                {isEditFiles && fileIsAvailable && (
                  <div
                    onClick={() => {
                      if (!fileIsAvailable.id || !fileIsAvailable.uploaded) {
                        setFiles(s =>
                          s.filter(el => el.file.name !== fileIsAvailable.file.name)
                        );
                      } else {
                        axios
                          .delete(`/api/shop/tender/attachment/${fileIsAvailable.id}`)
                          .then(() => {
                            setFiles(s => s.filter(el => el.id !== fileIsAvailable.id));
                          })
                          .catch(() => {
                            enqueueSnackbar(fm('ERROR'), {
                              variant: 'error',
                            });
                          });
                      }
                    }}
                    className={classes.fileBtn}
                    style={{ backgroundColor: 'rgba(253, 57, 122,0.1)' }}
                  >
                    <img alt='check' src={toAbsoluteUrl(`/media/logos/delete.svg`)} />
                  </div>
                )}
                {!fileIsAvailable && isEditFiles && (
                  <DropZoneBtn
                    item={item}
                    currentType={currentType}
                    setCurrentType={setCurrentType}
                    uploadFiles={uploadFiles}
                  />
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default React.memo(UploadFilesForm);
