import React, { useCallback, useEffect, useState } from 'react';
import { Button, MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import NumberFormatCustom from '../../../../components/formComponents/NumberFormatCustom';
import { ICategoryItem } from '../../categories/interfaces';
import { useFormatMessage } from '../../../../hooks';
import { useStylesSearchPage } from '../hooks/useStyles';

interface IProps {
  id: string | undefined;
  formik: any;
  editLoading: boolean;
  categories: ICategoryItem[] | undefined;
  handleDeleteSearch: () => void;
}

const levelsSubCategories = [1, 2, 3, 4, 5];

export const SearchForm: React.FC<IProps> = ({
  id,
  formik,
  editLoading,
  categories,
  handleDeleteSearch,
}) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const intl = useIntl();
  const classes = useStylesSearchPage();
  const [subCategories, setSubCategories] = useState<ICategoryItem[][]>([]);

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formik;

  const categoryName = useCallback(
    (category: ICategoryItem) => {
      const { locale } = intl;
      // @ts-ignore
      return locale === 'ru' ? category.name : category.names[`name_${locale}`];
    },
    [intl.locale]
  );

  const getSubCategories = useCallback(
    (level: number) => {
      return subCategories[level];
    },
    [subCategories]
  );

  useEffect(() => {
    if (values.mainCategoryId && categories) {
      const newCategories = categories.find(
        item => item.id?.toString() === values.mainCategoryId.toString()
      );
      if (newCategories && newCategories.children) {
        if (
          subCategories[1] &&
          newCategories.children[0] &&
          subCategories[1].length > 0 &&
          subCategories[1][0].id !== newCategories.children[0].id
        ) {
          levelsSubCategories.forEach(level => setFieldValue(`categoryId_${level}`, ''));
        }
        const subCategoriesLvl1 = newCategories.children;
        const subCategoriesLvl2: ICategoryItem | undefined =
          values.categoryId_1 &&
          subCategoriesLvl1?.length &&
          newCategories.children.find(
            item => item.id?.toString() === values.categoryId_1?.toString()
          );
        const subCategoriesLvl3: ICategoryItem | undefined =
          values.categoryId_2 &&
          subCategoriesLvl2?.children?.length &&
          subCategoriesLvl2.children.find(
            item => item.id?.toString() === values.categoryId_2?.toString()
          );
        const subCategoriesLvl4: ICategoryItem | undefined =
          values.categoryId_3 &&
          subCategoriesLvl3?.children?.length &&
          subCategoriesLvl3.children.find(
            item => item.id?.toString() === values.categoryId_3?.toString()
          );
        const subCategoriesLvl5: ICategoryItem | undefined =
          values.categoryId_4 &&
          subCategoriesLvl4?.children?.length &&
          subCategoriesLvl4.children.find(
            item => item.id?.toString() === values.categoryId_4?.toString()
          );
        if (subCategoriesLvl1.length === 0) {
          setFieldValue(`categoryId_1`, '');
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl2?.children?.length === 0) {
          setFieldValue(`categoryId_2`, '');
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl3?.children?.length === 0) {
          setFieldValue(`categoryId_3`, '');
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl4?.children?.length === 0) {
          setFieldValue(`categoryId_4`, '');
          setFieldValue(`categoryId_5`, '');
        }
        if (subCategoriesLvl5?.children?.length === 0) {
          setFieldValue(`categoryId_5`, '');
        }
        setSubCategories(state => {
          const newArr = [...state];
          newArr[1] = subCategoriesLvl1.length ? subCategoriesLvl1 : [];
          newArr[2] = subCategoriesLvl2?.children?.length ? subCategoriesLvl2?.children : [];
          newArr[3] = subCategoriesLvl3?.children?.length ? subCategoriesLvl3?.children : [];
          newArr[4] = subCategoriesLvl4?.children?.length ? subCategoriesLvl4?.children : [];
          newArr[5] = subCategoriesLvl5?.children?.length ? subCategoriesLvl5?.children : [];
          return newArr;
        });
      } else {
        setSubCategories([]);
      }
    } else {
      setSubCategories([]);
    }
  }, [
    categories,
    values.mainCategoryId,
    values.categoryId_1,
    values.categoryId_2,
    values.categoryId_3,
    values.categoryId_4,
    values.categoryId_5,
  ]);

  return (
    <>
      <TextField
        select
        label={fm('ORDER.FILTER.TYPE')}
        margin='normal'
        name='status'
        value={values.status || 'new'}
        variant='outlined'
        onBlur={handleBlur('status')}
        onChange={handleChange}
        helperText={touched.status && errors.status}
        error={Boolean(touched.status && errors.status)}
        className={classes.field}
      >
        <MenuItem value='new'>{fm('ORDER.TYPE.NEW')}</MenuItem>
        <MenuItem value='completed'>{fm('ORDER.TYPE.COMPLETED')}</MenuItem>
        <MenuItem value='canceled'>{fm('ORDER.TYPE.CANCELED')}</MenuItem>
        <MenuItem value='in_processing'>{fm('ORDER.TYPE.PROCCESS')}</MenuItem>
      </TextField>
      <TextField
        select
        label={fm('PRODUCT.EDIT.CATEGORY')}
        margin='normal'
        name='mainCategoryId'
        value={values.mainCategoryId || ''}
        variant='outlined'
        onBlur={handleBlur('mainCategoryId')}
        onChange={handleChange}
        helperText={touched.mainCategoryId && errors.mainCategoryId}
        error={Boolean(touched.mainCategoryId && errors.mainCategoryId)}
        className={classes.field}
      >
        {categories &&
          categories.map(item => {
            const nameLang = categoryName(item);
            return (
              <MenuItem
                className={!nameLang ? classes.fontNoLang : classes.labelText}
                key={item.id?.toString()}
                value={item.id?.toString()}
              >
                {nameLang || item.name}
              </MenuItem>
            );
          })}
      </TextField>
      {levelsSubCategories.map(level => {
        const subCategories = getSubCategories(level);
        if (subCategories && subCategories?.length > 0) {
          return (
            <TextField
              select
              label={`${fm('PRODUCT.EDIT.SUBCATEGORY')} ${level}`}
              margin='normal'
              name={`categoryId_${level}`}
              value={values[`categoryId_${level}`] || ''}
              variant='outlined'
              onBlur={handleBlur(`categoryId_${level}`)}
              onChange={handleChange}
              disabled={!values.mainCategoryId}
              helperText={touched[`categoryId_${level}`] && errors.categoryId}
              error={Boolean(touched[`categoryId_${level}`] && errors.categoryId)}
              className={classes.field}
            >
              {subCategories.length > 0 &&
                subCategories.map(item => {
                  const nameLang = categoryName(item);
                  return (
                    <MenuItem
                      className={!nameLang ? classes.fontNoLang : classes.labelText}
                      key={item.id?.toString()}
                      value={item.id?.toString()}
                    >
                      {nameLang || item.name}
                    </MenuItem>
                  );
                })}
            </TextField>
          );
        }
        return <></>;
      })}

      <TextField
        className={classes.field}
        type='text'
        label={fm('SEARCH.PRICE')}
        margin='normal'
        name='price'
        value={values.price || ''}
        variant='outlined'
        onBlur={handleBlur('price')}
        onChange={handleChange}
        helperText={touched.price && errors.price}
        error={Boolean(touched.price && errors.price)}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />
      <TextField
        className={classes.field}
        type='text'
        label={fm('PRODUCT.EDIT.NAME')}
        margin='normal'
        name='name'
        value={values.name || ''}
        variant='outlined'
        onBlur={handleBlur('name')}
        onChange={handleChange}
        helperText={touched.name && errors.name}
        error={Boolean(touched.name && errors.name)}
      />

      <TextField
        className={classes.field}
        type='text'
        label={fm('SEARCH.DESCRIPTION')}
        margin='normal'
        name='description'
        value={values.description || ''}
        variant='outlined'
        onBlur={handleBlur('description')}
        onChange={handleChange}
        helperText={touched.description && errors.description}
        error={Boolean(touched.description && errors.description)}
        rows={8}
        multiline
      />

      <div className={classes.actions}>
        <div>
          <Button
            onClick={() => navigate(-1)}
            className={classes.buttons}
            variant='outlined'
            color='primary'
          >
            {fm('CATEGORY.BUTTON.CANCEL')}
          </Button>
          <ButtonWithLoader
            disabled={editLoading}
            loading={editLoading}
            onPress={handleSubmit}
          >
            {id ? fm('COMMON.BUTTON.SAVE') : fm('PRODUCT.BUTTON.ADD')}
          </ButtonWithLoader>
        </div>
        {id && (
          <Button
            onClick={handleDeleteSearch}
            className={classes.deleteBtns}
            variant='outlined'
          >
            {fm('COMMON.BUTTON.DELETE')}
          </Button>
        )}
      </div>
    </>
  );
};
