import React, { useEffect } from 'react';
import { Typography, TextField, MenuItem, Box } from '@mui/material';

import { useFormatMessage, useShowErrors } from '../../../../hooks';
import { useStylesHeaderEditPricing } from '../hooks/useStyles';
import { useAxiosPostCallback } from '../../../../hooks/useAxiosGet';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../components/ui/Buttons/ButtonWithLoader';

type TProps = {
  formik: any;
  checkedId: {
    product_id: number;
    price: number;
    original_price: number;
  }[];
  setCheckedId: any;
  getProducts: any;
  setCheckedIdActive: any;
  checkedIdActive: number[];
};

const ProductsEditPricingHeader: React.FC<TProps> = ({
  formik,
  checkedId,
  setCheckedId,
  getProducts,
  checkedIdActive,
}) => {
  const fm = useFormatMessage();
  const classes1 = useStylesHeaderEditPricing();
  const { classes } = classes1;
  const { data: res, err, makeRequest } = useAxiosPostCallback();

  const { values, handleBlur, handleChange, setFieldValue } = formik;

  const priceChange = (price: number) => {
    let newPrice = price;

    values.priceUp &&
      (newPrice = parseFloat(
        (newPrice * (1 + values.priceUp / 100)).toFixed(+values.roundingType)
      ));
    values.priceDown &&
      (newPrice = parseFloat(
        (newPrice * (1 - values.priceDown / 100)).toFixed(+values.roundingType)
      ));

    values.amountUp && (newPrice = price + values.amountUp);
    values.amountDown && (newPrice = price - values.amountDown);

    setFieldValue('priceDown', '');
    setFieldValue('priceUp', '');
    setFieldValue('amountDown', '');
    setFieldValue('amountUp', '');

    return newPrice;
  };

  const handleTextFieldChange = (event: any, fieldName: string) => {
    const { value } = event.target;

    // Устанавливаем значение в текущем поле
    setFieldValue(fieldName, +value);

    // Очищаем другие поля в зависимости от введенного значения
    if (fieldName === 'priceUp' && value) {
      setFieldValue('priceDown', '');
    } else if (fieldName === 'priceDown' && value) {
      setFieldValue('priceUp', '');
    } else if (fieldName === 'amountUp' && value) {
      setFieldValue('amountDown', '');
    } else if (fieldName === 'amountDown' && value) {
      setFieldValue('amountUp', '');
    }
  };

  const applyChange = () => {
    if (checkedId.length) {
      setCheckedId((prevCheckedId: any) =>
        prevCheckedId.map((item: any) => {
          return {
            ...item,
            price: !checkedIdActive.includes(item?.product_id)
              ? item.price
              : priceChange(item.price),
          };
        })
      );
    }
  };

  function checkPriceDifference(product: any) {
    return product.price !== product.original_price;
  }

  const changeSave = () => {
    const pricesToSend = checkedId.map(item => ({
      price: item.price,
      product_id: item.product_id,
    }));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < checkedId.length; i++) {
      if (checkPriceDifference(checkedId[i])) {
        makeRequest('/api/shop/products_price', {
          prices: pricesToSend,
        });
        break;
      }
    }
  };

  const resetPrice = () => {
    setCheckedId((prevCheckedId: any) =>
      prevCheckedId.map((item: any) => {
        return {
          ...item,
          price: !checkedIdActive.includes(item?.product_id)
            ? item.price
            : item.original_price,
        };
      })
    );
  };

  useEffect(() => {
    if (res) {
      getProducts();
    }
  }, [res]);

  useShowErrors([err]);

  return (
    <>
      <div className={classes.container}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <Typography className={classes.title}>
            {fm('PRODUCT.EDIT.PRICING.TITLE').toLocaleUpperCase()}
          </Typography>
          <Typography
            onClick={resetPrice}
            style={{ color: '#FD397A', fontSize: 17, fontWeight: 'bold', cursor: 'pointer' }}
          >
            {fm('PRODUCT.PRICING.RESET').toLocaleUpperCase()}
          </Typography>
        </div>
        <div className={classes.inp}>
          <Box component='form' style={{ display: 'flex', gap: 10, marginRight: 15 }}>
            <TextField
              type='number'
              focused
              fullWidth
              style={{ height: 50, maxWidth: 150 }}
              variant='outlined'
              label={`% ${fm('PRODUCT.PRICE.UP')}`}
              color='primary'
              name='priceUp'
              value={values.priceUp || ''}
              onBlur={handleBlur('priceUp')}
              onChange={e => handleTextFieldChange(e, 'priceUp')}
            />

            <TextField
              type='number'
              focused
              fullWidth
              style={{ height: 50, width: 150 }}
              variant='outlined'
              label={`% ${fm('PRODUCT.PRICE.DOWN')}`}
              color='primary'
              name='priceDown'
              value={values.priceDown || ''}
              onBlur={handleBlur('priceDown')}
              onChange={e => handleTextFieldChange(e, 'priceDown')}
            />

            <TextField
              type='number'
              select
              fullWidth
              focused
              style={{ height: 50, width: 150 }}
              variant='outlined'
              label={`% ${fm('PRODUCT.PRICE.ROUNDING.TYPE')}`}
              color='primary'
              name='roundingType'
              value={values.roundingType || ''}
              onBlur={handleBlur('roundingType')}
              onChange={handleChange}
            >
              <MenuItem value='2'>0.00</MenuItem>
              <MenuItem value='1'>0.0</MenuItem>
              <MenuItem value='0'>0.</MenuItem>
            </TextField>
          </Box>

          <Box
            component='form'
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'end',
              flexWrap: 'wrap',
            }}
          >
            <TextField
              type='number'
              focused
              style={{ width: 150, height: 50 }}
              variant='outlined'
              label={`${fm('PRODUCT.AMOUNT.UP')}, CZK`}
              color='primary'
              name='amountUp'
              value={values.amountUp || ''}
              onBlur={handleBlur('amountUp')}
              onChange={e => handleTextFieldChange(e, 'amountUp')}
            />

            <TextField
              type='number'
              focused
              style={{ width: 150, height: 50 }}
              variant='outlined'
              label={`${fm('PRODUCT.AMOUNT.DOWN')}, CZK`}
              color='primary'
              name='amountDown'
              value={values.amountDown || ''}
              onBlur={handleBlur('amountDown')}
              onChange={e => handleTextFieldChange(e, 'amountDown')}
            />
          </Box>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <ButtonWithLoader onPress={applyChange} theme={ButtonTheme.PRIMARY} margin>
              {fm('PRODUCT.APPLY.CHANGE')}
            </ButtonWithLoader>

            <ButtonWithLoader onPress={changeSave} theme={ButtonTheme.PRIMARY} margin>
              {fm('PRODUCT.SAVE.PRICE')}
            </ButtonWithLoader>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductsEditPricingHeader);
